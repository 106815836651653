export const dataHero=[
    {
    id:'1',
    title:'ENERGY EFFICIENCY REDEFINED',
    buttonText:'Contact us',
    variant:'contained',
    imgSrc:require('../assets/src/hero/heroHome-1.jpg')
    },
    {
    id:'2',
    title:'SPS products & Services',
    buttonText:'Contact us',
    variant:'contained',
    imgSrc:require('../assets/src/hero/heroHome-2.jpg')
    },
]


export const dataInnovations =[
    {
    id:'innovation',
    title:'SPS Innovations at Work',
list:[
{
    id:'1',
    title:'Our Products',
    text:'The product range manufactured and supplied by SPS Transformers caters to all the needs of Power & Distribution.',
    buttonText:'Read more',
    variant:'outlined',
    imgAlt:'ourProducts-img',
    imgSrc:require('../assets/src/innovation/ourProducts.png'),
    link:'/productservices'

},
{
    id:'2',
    title:'Services',
    text:'We offer comprehensive maintenance programs that define maintenance work and the required spare parts for different stages.',
    buttonText:'Read more',
    variant:'outlined',
    imgAlt:'services-img',
    imgSrc:require('../assets/src/innovation/services.png'),
    link:'/productservices'

},
{
    id:'3',
    title:'Engineering',
    text:'Backed by a strong team of Design & Engineering experts with rich experience of over 3 decades, SPS gives their customers world class products.',
    buttonText:'Read more',
    variant:'outlined',
    imgAlt:'engineering-img',
    imgSrc:require('../assets/src/innovation/engineering.png'),
    link:'/engineering'

},
]
}]

export const dataSupportTeams =[
{
    id:'1',
    name:'Mr.Satheesh R',
    position:'Head Marketing',
    number:'+91 9840017286',
},
{
    id:'2',
    name:'Mr.Yuvaraj M',
    position:'Manager Marketing',
    number:'+91 9500032992',
},
{
    id:'3',
    name:'Mr.Harish P',
    position:'Asst. Manager Marketing',
    number:'+91 7397788176',
},{
    id:'4',
    name:'Arathi K',
    position:'Senior Engineer Techno Commercial',
    number:'+91 9176552211, +91 7550226771',
},

]

export const dataClients=[
{
    id:'1',
    imgSrc:require('../assets/src/clients/Janatics.jpg')
},   
{
    id:'2',
    imgSrc:require('../assets/src/clients/KHGroup.jpg')
},   
{
    id:'3',
    imgSrc:require('../assets/src/clients/arihantFoundation.jpg')
},   
{
    id:'4',
    imgSrc:require('../assets/src/clients/avt.jpg')
},   
{
    id:'5',
    imgSrc:require('../assets/src/clients/aypolsPolymers.jpg')
},    
{
    id:'6',
    imgSrc:require('../assets/src/clients/chennaiSilks.jpg')
},   
{
    id:'7',
    imgSrc:require('../assets/src/clients/chennaiUrology.jpg')
},    
{
    id:'8',
    imgSrc:require('../assets/src/clients/elgiEquipment.jpg')
},   
{
    id:'9',
    imgSrc:require('../assets/src/clients/elgiIndustries.jpg')
},   
{
    id:'10',
    imgSrc:require('../assets/src/clients/gPlast.jpg')
},   
{
    id:'11',
    imgSrc:require('../assets/src/clients/kurinjiHospital.jpg')
},   
{
    id:'12',
    imgSrc:require('../assets/src/clients/shriKailash.jpg')
},     
{
    id:'13',
    imgSrc:require('../assets/src/clients/stBedeSchool.jpg')
},   
{
    id:'14',
    imgSrc:require('../assets/src/clients/tneb.jpg')
},   
{
    id:'15',
    imgSrc:require('../assets/src/clients/tvsSundaram.jpg')
},   
{
    id:'16',
    imgSrc:require('../assets/src/clients/vgmHealthcare.jpg')
},   
]