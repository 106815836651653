import React from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { contacts, dataAbout, dataContactUs, dataPolicyProduct, dataQuick } from '../data/FooterMenuItems';
import { Link } from 'react-router-dom';
import RequestAQuote from '../components/RequestAQuote';
import ServiceSupport from '../components/ServiceSupport';

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  ...theme.typography.body1,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(4),
  textTransform: 'capitalize',
  color: theme.palette.primary.dark,
  margin:0
}));


const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openService, setOpenService] = React.useState(false);
  const handleOpenService = () => setOpenService(true);
  const handleCloseService = () => setOpenService(false);
  return (
    <React.Fragment>
   <FooterBox component="footer">
    <Container>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
      
        <Grid item xs={6} md={3}>
          <List>
          {dataContactUs.map((item)=>
          <>
          <Typography key={item.id} variant="h6" sx={{textTransform:'uppercase',textDecoration:'underline',mb:6}}>{item.title}</Typography>
          {item.sub.map((item)=>
          <ListItem key={item.id} sx={{flexDirection:'column',alignItems:'start',mb:4}} disablePadding>
          <Typography variant="h6" sx={{textTransform:'capitalize'}}>{item.subTitle}</Typography>
          <Typography variant='body2' style={{display:item.number1?'block':'none'}}>{item.number1}</Typography>
          <Typography variant='body2' style={{display:item.number2?'block':'none'}}>{item.number2}</Typography>
          <Link to={item.link1} style={{display:item.text1?'block':'none'}}>
          <Typography variant='body2'>{item.text1}</Typography>
          </Link>
          <Link to={item.link2} style={{display:item.text2?'block':'none'}}>
          <Typography variant='body2'>{item.text2}</Typography>
          </Link>
          </ListItem>
          )}
          </>
          )}
        </List>
        </Grid>
        <Grid item xs={6} md={3}>
        <List>
        {dataAbout.map((item)=>
        <>
        <Typography key={item.id} variant="h6" sx={{textTransform:'uppercase',textDecoration:'underline',mb:6}}>{item.title}</Typography>
        {item.list.map((item)=>
        <ListItem key={item.id} sx={{flexDirection:'column',alignItems:'start',mb:2}} disablePadding>
        <Link to={item.link}>
        <Typography variant='body2'>{item.listItem}</Typography>
        </Link>
        </ListItem>
        )}
        </>
        )}
        </List>
        </Grid>
        <Grid item xs={6} md={3}>
          <List>
          {dataPolicyProduct.map((item)=>
          <>
        <Typography key={item.id} variant="h6" sx={{textTransform:'uppercase',textDecoration:'underline',mb:6}}>{item.title}</Typography>
        {item.sub.map((item)=>
        <ListItem key={item.id} sx={{mb:4,flexDirection:'column',alignItems:'start',mb:4}} disablePadding>
        <Typography variant="h6" style={{display:item.subTitle?'block':'none'}} sx={{textTransform:'capitalize'}}>{item.subTitle}</Typography>
        <Link to={item.link1}>
        <Typography variant='body2'>{item.text1}</Typography>
        </Link>
        <Link to={item.link2}>
        <Typography variant='body2'>{item.text2}</Typography>
        </Link>
        <Link to={item.link3}>
        <Typography variant='body2'>{item.text3}</Typography>
        </Link>
        </ListItem>
        )}
          </>
          )}
        </List>
        </Grid>
        <Grid item xs={6} md={3}>
        <List>
        <Typography variant="h6" sx={{textTransform:'uppercase',textDecoration:'underline',mb:6}}>Quick Links</Typography>
        <ListItem sx={{flexDirection:'column',alignItems:'start',mb:2,textDecoration:'underline'}} disablePadding>
        <Link to="newsmedia/gallery">
        <Typography variant='body2'>Gallery</Typography>
        </Link>
        </ListItem>
        <ListItem sx={{flexDirection:'column',alignItems:'start',mb:2,textDecoration:'underline'}} disablePadding>
        <Link to="/contact">
        <Typography variant='body2'>Contact</Typography>
        </Link>
        </ListItem>
        <ListItem sx={{flexDirection:'column',alignItems:'start',mb:2,textDecoration:'underline',cursor:'pointer'}} disablePadding>
        <Typography variant='body2' onClick={handleOpenService}>Service Support</Typography>
        </ListItem>
        <ListItem sx={{flexDirection:'column',alignItems:'start',mb:2,textDecoration:'underline',cursor:'pointer'}} disablePadding>
        <Typography variant='body2' onClick={handleOpen}>Request a Quote</Typography>
        </ListItem>
        <ListItem sx={{flexDirection:'column',alignItems:'start',mb:2,textDecoration:'underline'}} disablePadding>
        <Link to="newsmedia/downloads">
        <Typography variant='body2'>Downloads</Typography>
        </Link>
        </ListItem>
        </List>
        </Grid>
      
      </Grid>
    </Box>
    </Container>
  </FooterBox>
  <RequestAQuote open={open}  handleClose={handleClose} />
    <ServiceSupport open={openService}  handleClose={handleCloseService} />
    </React.Fragment>
  )
}

export default Footer