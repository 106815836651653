import React from 'react';
import Hero from '../../../components/Hero';
import Content from './Content';

const Info = () => {
  return (
    <React.Fragment>
    <Hero title="Senior Data Engineer"
    text="for Full-time"
    className="info"
    buttonText="Apply to this job"
    color="primary"
    variant="contained"
    />
    <Content />
    </React.Fragment>
  )
}

export default Info