import React from 'react';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import {List,ListItem,ListItemIcon} from '@mui/material';
import { dataQuality } from '../../data/PolicyCredentials';
import { styled } from '@mui/material/styles';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Link } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';

const ViewBox = styled(Box)(({theme}) => ({

  }));

const Quality = () => {
  return (
    <React.Fragment>
    <ViewBox sx={{py:{xs:3,md:6}}}>
      <Container>
      {dataQuality.map((item,index)=>
    <Grid container columnSpacing={4} rowSpacing={6} key={item.id} alignItems="center">
    <Grid item xs={12} md={6}>
      <Box>
      <img src={item.imgSrc} alt={item.imgAlt} width="100%" />
      </Box>
  </Grid>
  <Grid item xs={12} md={6}>
    <Box key={item.id}>
    <Typography variant='h6' color="primary">
    {item.subTitle}
    </Typography>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <Typography variant='body1' color='text.main' mb={4}>
    {item.text}
    </Typography>
    <List>
    {item.quality.map((item)=>
      <ListItem key={item.id}>
        {/* <img src={item.imgSrc} alt={item.imgAlt} style={{width:'20px',height:'20px',marginTop:'5px'}}/> */}
        <BoltIcon color="secondary" />
      <Typography variant='body1' color='text.main' mb={4}>{item.text}</Typography>
      </ListItem>
    )}
  </List>
  <Link to={item.link}>
  <Button variant={item.variant} color='primary' size="large">{item.buttonText}</Button>
  </Link>
  </Box>
  </Grid>
  </Grid>)}
  </Container>
  </ViewBox>
    </React.Fragment>
  )
}

export default Quality