import React from 'react';
import Box from '@mui/material/Box';
import arrow from '../assets/src/icon/arrow.png';
import { Button, Container, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const HeroBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.comman.main,
  display:'flex',
  alignItems:'center',
}));

const Hero = (props) => {
  return (
    <React.Fragment>
    <Box className={`${"hero"} ${props.className}`} py={{xs:8,md:16}}>
    <Container className={props.slide}>
    <HeroBox>
    <Grid container spacing={4} alignItems="center">
    <Grid item xs={12} md={props.md || 8}>
    <Box>
    <Stack direction="row" spacing={3}>
    <img src={arrow} alt="arrow-img" style={{width:'30px',height:'30px',marginTop:'1.5rem'}}/>
    <Box>
    <Typography variant='h1'>
    {props.title}
    </Typography>
    <Typography variant='body1' mb={4}>
    {props.text}
    </Typography>
    <Link to={props.link}>
    <Button variant={props.variant || 'outlined'} color={props.color} size="large">{props.buttonText}</Button>
    </Link>
    </Box>
    </Stack>
    </Box>
    </Grid>
    
    </Grid>

    </HeroBox>
    </Container>
    </Box>
    </React.Fragment>
  )
}

export default Hero
