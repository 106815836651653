export const dataManu =[
  {
    id:'manufacturing',
    title:'Our manufacturing process',
    info:[
      {
      id:'1',
      text:'The Manufacturing Process is governed by strict adherence to the Reference Standards of IS 1180 (Part 1) : 2014/ IS 2026, IEC, ANSI, IEEE Standards',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
      {
      id:'2',
      text:'As per the BIS Certification Norms and as per the Manufacturing Process Flow Chart and Quality Assurance Plan.',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    ],
  
  list:[
{
    id:'1',
    title:'Requirements Gathering',
    imgSrc:require('../assets/src/icon/flaticon/statistics.png'),
    imgAlt:'statistics-img'
},
{
    id:'2',
    title:'Electrical Schematic Design',
    imgSrc:require('../assets/src/icon/flaticon/logo-design.png'),
    imgAlt:'logo-design-img'
},
{
    id:'3',
    title:'3D Modeling',
    imgSrc:require('../assets/src/icon/flaticon/3d.png'),
    imgAlt:'3d-img'
},
{
    id:'4',
    title:'Prototying',
    imgSrc:require('../assets/src/icon/flaticon/idea.png'),
    imgAlt:'idea-img'
}
  ]
  
  }
]


export const workerData= [
  {
  id:'1',
  imgSrc:require('../assets/src/post/worker1.jpg')
  },
  {
  id:'2',
  imgSrc:require('../assets/src/post/worker2.jpg')
  },
  {
  id:'3',
  imgSrc:require('../assets/src/post/worker3.jpg')
  },
]
