import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { dataFactory } from '../../data/PolicyCredentials';
import {List,ListItem,ListItemIcon} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useOutletContext } from 'react-router-dom';
import line from '../../assets/src/icon/line.png';
import BoltIcon from '@mui/icons-material/Bolt';

const FactoryBox = styled(Box)(({theme}) => ({
    backgroundColor:theme.palette.primary.light,
    color:theme.palette.comman.main,
    paddingTop:theme.spacing(8),
    paddingBottom:theme.spacing(8),
  }));

const Factory = (props) => {
  const handleOpen = useOutletContext();
  return (
    <React.Fragment>
    <FactoryBox>
    <Container>
    {dataFactory.map((item)=>
    <Grid container rowSpacing={6} columnSpacing={20} key={item.id}>
  <Grid item xs={12} md={6}>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <List>
      {item.factory.map((item)=>
      <ListItem key={item.id}>
        {/* <img src={item.imgSrc} alt={item.imgAlt} style={{width:'25px',height:'25px'}}/> */}
        <BoltIcon color="secondary" />
      <Typography variant='body1' mb={4}>{item.text}</Typography>
      </ListItem>
      )}
  </List>
  <Button variant={item.variant} color='primary' size="large" onClick={props.handleOpen}>{item.buttonText}</Button>
  </Grid>
  <Grid item xs={12} md={6}>
    <Box sx={{position:'relative'}}>
    <img src={line} alt="line-img" className='lineCurve' />
  <List sx={{pl:{xs:0,md:4}}}>
    {item.card.map((card)=>
    <ListItem key={card.id} sx={{backgroundColor:'comman.main',p:2,borderRadius:'1rem',mb:3,width:'fit-content',gap:2}}>
        <ListItemIcon sx={{minWidth:'auto'}}>
        <CheckCircleIcon color='success' />
        </ListItemIcon>
        <Typography variant='h6' mb={0} color='primary.dark'>
        {card.title} 
        </Typography>
      </ListItem>
      )}
  </List>
  </Box>
  </Grid>
  </Grid>
  )}
    </Container>
    </FactoryBox>
   </React.Fragment>
  )
}

export default Factory