import React from 'react'
import Hero from '../../components/Hero';
import SupportTeam from './SupportTeam';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Client from './Client';
import CallToAction from '../../components/CallToAction';
import Innovation from './Innovation';
import AboutSps from './AboutSps';
import QualityPolicy from './QualityPolicy';
import HeroHome from './HeroHome';
import NewsUpdate from './NewsUpdate/NewsUpdate';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';
import ServiceSupport from '../../components/ServiceSupport';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';


const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openService, setOpenService] = React.useState(false);
  const handleOpenService = () => setOpenService(true);
  const handleCloseService = () => setOpenService(false);
  // const handleOpen = useOutletContext();
  // const handleOpenService = useOutletContext();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  return (
    <React.Fragment>
    <HeroHome />
    <NewsUpdate />
   <Innovation />
   <AboutSps />
   <QualityPolicy />
    <CallToAction title="Service Support" text="Please contact us with the provided contact details and also fill in the enquiry form available if you need Service Support for your purchase or product related enquiries."
    buttonTextPrimary="Request a Quote" buttonTextSecondary="Service Support" onPrimaryClick={handleOpen} onSecondaryClick={handleOpenService} />
    <SupportTeam />
    <Client />
    <RequestAQuote open={open}  handleClose={handleClose} />
    <ServiceSupport open={openService}  handleClose={handleCloseService} />
    
    </React.Fragment>
  )
}

export default Home