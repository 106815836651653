export const ClientsIndustriesData = [
    {
    id:'1',
    imgSrc : require('../assets/src/clients/Others/Lakshmi Mills Comapny Limited.png'),
    },
    {
    id:'2',
    imgSrc : require('../assets/src/clients/Others/bullagro-logo.png'),
    },
    {
    id:'3',
    imgSrc : require('../assets/src/clients/Others/Propel.png'),
    },
    {
    id:'4',
    imgSrc : require('../assets/src/clients/Others/Elgi.png'),
    },
    {
    id:'5',
    imgSrc : require('../assets/src/clients/Others/chennaiSilks.jpg'),
    },
    {
    id:'6',
    imgSrc : require('../assets/src/clients/Automobile/Apachi Foot Wear.jpg'),
    },
    {
    id:'7',
    imgSrc : require('../assets/src/clients/Automobile/BYD.png'),
    },
    {
    id:'8',
    imgSrc : require('../assets/src/clients/Automobile/CEV Engineering Pvt Ltd.jpg'),
    },
    {
    id:'9',
    imgSrc : require('../assets/src/clients/Automobile/Dooth.jpg'),
    },
    {
    id:'10',
    imgSrc : require('../assets/src/clients/Automobile/Eicher.png'),
    },
    {
    id:'11',
    imgSrc : require('../assets/src/clients/Automobile/Electra Power.png'),
    },
    {
    id:'12',
    imgSrc : require('../assets/src/clients/Automobile/Hightemp-Furnaces-Ltd_.jpg'),
    },
    {
    id:'13',
    imgSrc : require('../assets/src/clients/Automobile/ICMPL.jpg'),
    },
    {
    id:'14',
    imgSrc : require('../assets/src/clients/Automobile/KCALTECH.jpg'),
    },
    {
    id:'15',
    imgSrc : require('../assets/src/clients/Automobile/KR Fues.png'),
    },
    {
    id:'16',
    imgSrc : require('../assets/src/clients/Automobile/Koyas.png'),
    },
    {
    id:'17',
    imgSrc : require('../assets/src/clients/Automobile/Kubata.png'),
    },
    {
    id:'18',
    imgSrc : require('../assets/src/clients/Automobile/MK Auto.jpg'),
    },
    {
    id:'19',
    imgSrc : require('../assets/src/clients/Automobile/Nippon.png'),
    },
    {
    id:'20',
    imgSrc : require('../assets/src/clients/Automobile/Syndicate Wiper.jpg'),
    },
    {
    id:'21',
    imgSrc : require('../assets/src/clients/Automobile/TVS.jpg'),
    },
    {
    id:'22',
    imgSrc : require('../assets/src/clients/Automobile/Thomas Iyadurai.jpg'),
    },
    {
    id:'23',
    imgSrc : require('../assets/src/clients/Automobile/VTK.jpg'),
    },
    {
    id:'24',
    imgSrc : require('../assets/src/clients/Automobile/electra-EV.jpg'),
    },
    {
    id:'25',
    imgSrc : require('../assets/src/clients/Automobile/filtercat.png'),
    },
    {
    id:'26',
    imgSrc : require('../assets/src/clients/Automobile/krishca-strapping-ipo-logo.png'),
    },
    {
    id:'27',
    imgSrc : require('../assets/src/clients/Automobile/mindalogo.png'),
    },
    {
    id:'28',
    imgSrc : require('../assets/src/clients/Food/AVT Group.jpg'),
    },
    {
    id:'29',
    imgSrc : require('../assets/src/clients/Food/Abad.png'),
    },
    {
    id:'30',
    imgSrc : require('../assets/src/clients/Food/Gold Cup.jpg'),
    },
    {
    id:'31',
    imgSrc : require('../assets/src/clients/Food/Milky_Mist_logo.png'),
    },
    {
    id:'32',
    imgSrc : require('../assets/src/clients/Food/Pavilizham.png'),
    },
    {
    id:'33',
    imgSrc : require('../assets/src/clients/Food/Podoran.png'),
    },
    {
    id:'34',
    imgSrc : require('../assets/src/clients/Food/Roshan Foods.png'),
    },
    {
    id:'35',
    imgSrc : require('../assets/src/clients/Food/amul.avif'),
    },
    {
    id:'36',
    imgSrc : require('../assets/src/clients/Food/kali-aerated-water-works-pvt-ltd-teynampet-chennai-soft-drink-manufacturers-1g12x26-250.webp'),
    },
    {
    id:'37',
    imgSrc : require('../assets/src/clients/Food/mlogo.webp'),
    },
    {
    id:'38',
    imgSrc : require('../assets/src/clients/Food/top_logo.gif'),
    },
    {
    id:'39',
    imgSrc : require('../assets/src/clients/Leather and Goods/Good Leather Group.jpg'),
    },
    {
    id:'40',
    imgSrc : require('../assets/src/clients/Leather and Goods/KH Group.jpg'),
    },
    {
    id:'41',
    imgSrc : require('../assets/src/clients/Leather and Goods/Sara Leather.jpg'),
    },
    {
    id:'42',
    imgSrc : require('../assets/src/clients/Leather and Goods/WALKAROO.png'),
    },
    {
    id:'43',
    imgSrc : require('../assets/src/clients/Others/AJS Infra.jpeg'),
    },
    {
    id:'44',
    imgSrc : require('../assets/src/clients/Others/Agni.png'),
    },
    {
    id:'45',
    imgSrc : require('../assets/src/clients/Others/Bagrecha Enterprises.png'),
    },
    {
    id:'46',
    imgSrc : require('../assets/src/clients/Others/BharatSteelLogo.png'),
    },
    {
    id:'47',
    imgSrc : require('../assets/src/clients/Others/Caplin-Point-Laboratories.png'),
    },
    {
    id:'48',
    imgSrc : require('../assets/src/clients/Others/Chettinad.png'),
    },
    {
    id:'49',
    imgSrc : require('../assets/src/clients/Others/Dhandapani Steel.gif'),
    },
    {
    id:'50',
    imgSrc : require('../assets/src/clients/Others/Emerald Jewellery.png'),
    },
    {
    id:'51',
    imgSrc : require('../assets/src/clients/Others/ITW.jpg'),
    },
    {
    id:'52',
    imgSrc : require('../assets/src/clients/Others/Indo Space.jpg'),
    },
    {
    id:'53',
    imgSrc : require('../assets/src/clients/Others/Jains Housing.webp'),
    },
    {
    id:'54',
    imgSrc : require('../assets/src/clients/Others/Linkra Wire and Cables.png'),
    },
    {
    id:'55',
    imgSrc : require('../assets/src/clients/Others/MAKINO-Logo.jpg'),
    },
    {
    id:'56',
    imgSrc : require('../assets/src/clients/Others/Mahendra Pumps.png'),
    },
    {
    id:'57',
    imgSrc : require('../assets/src/clients/Others/Motherson.png'),
    },
    {
    id:'58',
    imgSrc : require('../assets/src/clients/Others/Mysore ESDM.jpg'),
    },
    {
    id:'59',
    imgSrc : require('../assets/src/clients/Others/Orion Battery.png'),
    },
    {
    id:'60',
    imgSrc : require('../assets/src/clients/Others/PG Group.jpg'),
    },
    {
    id:'61',
    imgSrc : require('../assets/src/clients/Others/Palm Fibre.png'),
    },
    {
    id:'62',
    imgSrc : require('../assets/src/clients/Others/Roots.jpg'),
    },
    {
    id:'63',
    imgSrc : require('../assets/src/clients/Others/SLS Mills.jpg'),
    },
    {
    id:'64',
    imgSrc : require('../assets/src/clients/Others/Schnider Prototyping.png'),
    },
    {
    id:'65',
    imgSrc : require('../assets/src/clients/Others/Sristi Cot spin.png'),
    },
    {
    id:'66',
    imgSrc : require('../assets/src/clients/Others/TNCA Stadium.webp'),
    },
    {
    id:'67',
    imgSrc : require('../assets/src/clients/Others/URC.png'),
    },
    {
    id:'68',
    imgSrc : require('../assets/src/clients/Others/Virdis Engineering.jpg'),
    },
    {
    id:'69',
    imgSrc : require('../assets/src/clients/Others/WinWind Energy.jpg'),
    },
    {
    id:'70',
    imgSrc : require('../assets/src/clients/Others/agtmills_logo.png'),
    },
    {
    id:'71',
    imgSrc : require('../assets/src/clients/Others/dhl-logo.jpg'),
    },
    {
    id:'72',
    imgSrc : require('../assets/src/clients/Others/emufBYgVdahiU5wvd9SslVCzQirmkubw3kF2fILz.jpeg'),
    },
    {
    id:'73',
    imgSrc : require('../assets/src/clients/Others/luker_logo (1).png'),
    },
    {
    id:'74',
    imgSrc : require('../assets/src/clients/Others/rabwin-logo-website.png'),
    },
    {
    id:'75',
    imgSrc : require('../assets/src/clients/Others/visaka-logo-vector.png'),
    }
]
export const ClientsSolarData = [
    {
    id:'1',
    imgSrc : require('../assets/src/clients/Solar/DVN-energy-logo1.jpeg'),
    },
    {
    id:'2',
    imgSrc : require('../assets/src/clients/Solar/Virdis.jpg'),
    },
    {
    id:'3',
    imgSrc : require('../assets/src/clients/Solar/Inglo Green Energy.png'),
    },
    {
    id:'4',
    imgSrc : require('../assets/src/clients/Solar/Progen.png'),
    },
    {
    id:'5',
    imgSrc : require('../assets/src/clients/Solar/vijayanth.jpg'),
    },
    {
    id:'6',
    imgSrc : require('../assets/src/clients/Solar/Dailee.png'),
    },
    {
    id:'7',
    imgSrc : require('../assets/src/clients/Solar/Eternal Logo.jpg'),
    },
    {
    id:'8',
    imgSrc : require('../assets/src/clients/Solar/Jeyam Vijayam.jpeg'),
    },
    {
    id:'9',
    imgSrc : require('../assets/src/clients/Solar/PQS.jpg'),
    },
    {
    id:'10',
    imgSrc : require('../assets/src/clients/Solar/prathyusha-group-of-companies.jpg'),
    },
    {
    id:'11',
    imgSrc : require('../assets/src/clients/Solar/siemens-gamesa-renewable-energy-vector-logo.png'),
    },
]

export const ClientsGovernmentData = [
    {
    id:'1',
    imgSrc : require('../assets/src/clients/government/PWD.png'),
    },
    {
    id:'2',
    imgSrc : require('../assets/src/clients/government/CMWSSB.jpg'),
    },
    {
    id:'3',
    imgSrc : require('../assets/src/clients/government/TANTRANSC.jpg'),
    },
    {
    id:'4',
    imgSrc : require('../assets/src/clients/government/BESCOM.jpg'),
    },
    {
    id:'5',
    imgSrc : require('../assets/src/clients/government/TN.jpg'),
    }
]

export const ClientsEducationData = [
    {
    id:'1',
    imgSrc : require('../assets/src/clients/Education/Arunai Mediacl Colege.jpg'),
    },
    {
    id:'2',
    imgSrc : require('../assets/src/clients/Education/Annapoorna Medical College.png'),
    },
    {
    id:'3',
    imgSrc : require('../assets/src/clients/Education/smc_logo6.jpg'),
    },
    {
    id:'4',
    imgSrc : require('../assets/src/clients/Education/SA engineerg.webp'),
    },
    {
    id:'5',
    imgSrc : require('../assets/src/clients/Education/AMRIT-removebg-preview_2.png'),
    },
    {
    id:'6',
    imgSrc : require('../assets/src/clients/Education/AMerican  College.jpg'),
    },
    {
    id:'7',
    imgSrc : require('../assets/src/clients/Education/APL_Logo-03.png'),
    },
    {
    id:'8',
    imgSrc : require('../assets/src/clients/Automobile/Dooth.jpg'),
    },
    {
    id:'9',
    imgSrc : require('../assets/src/clients/Education/Jeeva Educational Trust.jpg'),
    },
    {
    id:'10',
    imgSrc : require('../assets/src/clients/Education/KASC_logo.png'),
    },
    {
    id:'11',
    imgSrc : require('../assets/src/clients/Education/PSGRKCW-Logo.jpg'),
    },
    
    {
    id:'12',
    imgSrc : require('../assets/src/clients/Education/Sri Kalishwari College.png'),
    },
    {
    id:'13',
    imgSrc : require('../assets/src/clients/Education/amclogo33.png'),
    },
    {
    id:'14',
    imgSrc : require('../assets/src/clients/Education/jmc_logo.png'),
    },
    {
    id:'15',
    imgSrc : require('../assets/src/clients/Education/logo-bedes.jpg'),
    },
    {
    id:'16',
    imgSrc : require('../assets/src/clients/Education/logo_tnau_main.jpg'),
    },
]

export const ClientsHealthData = [
    {
    id:'1',
    imgSrc : require('../assets/src/clients/Hospital/curi Hospital.webp'),
    },
    {
    id:'2',
    imgSrc : require('../assets/src/clients/Hospital/KMCH Logo.png'),
    },
    {
    id:'3',
    imgSrc : require('../assets/src/clients/Hospital/sankaraeye-colored-logo.webp'),
    },    
    {
    id:'4',
    imgSrc : require('../assets/src/clients/Hospital/Kurinji Hospital.jpg'),
    },
    {
    id:'5',
    imgSrc : require('../assets/src/clients/Hospital/KR Health Care.png'),
    },
    {
    id:'6',
    imgSrc : require('../assets/src/clients/Hospital/MAHER.png'),
    },
    {
    id:'7',
    imgSrc : require('../assets/src/clients/Hospital/RIO.png'),
    },
    {
    id:'8',
    imgSrc : require('../assets/src/clients/Hospital/St.Peters.png'),
    },
    {
    id:'9',
    imgSrc : require('../assets/src/clients/Hospital/Surendar Hospital.png'),
    },
    {
    id:'10',
    imgSrc : require('../assets/src/clients/Hospital/madha-medical-college-website-logo.png'),
    },
    {
    id:'11',
    imgSrc : require('../assets/src/clients/Hospital/sehat-logo-black.png'),
    },
    {
    id:'12',
    imgSrc : require('../assets/src/clients/Hospital/skkmt.png'),
    },
    {
    id:'13',
    imgSrc : require('../assets/src/clients/Hospital/vgm-logo-full.png'),
    },
]
