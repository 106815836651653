export const dataForm=[
    {
    id:'form',
    title:'Fill out the form and we will be in touch with you',
    imgSrc:require('../assets/src/hero/heroContact.png'),
    imgAlt:'banner-img',
    // input:[
    // {
    // id:'first',
    // label:'First Name'
    // },
    // {
    // id:'last',
    // label:'Last Name'
    // },
    // {
    // id:'email',
    // label:'Email Address'
    // },
    // {
    // id:'company',
    // label:'Company'
    // },
    // {
    // id:'number',
    // label:'Phone Number'
    // },
    // {
    // id:'message',
    // label:'Message'
    // },
    // ]
    },
]



export const dataLocation=[
  {
  id:'contact',
  title:'Contact SPS transformers',
  text:'we appreciate your interest in SPS Transformers. please select from the options below.',
  imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
  imgAlt:'thunder-img',
  buttonText:'Request a quote',
  variant:'contained',
  location:[
  {
  id:'1',
  iconSrc:require('../assets/src/icon/flaticon/location.png'),
  iconAlt:'location-icon',
  title:'corporate location',
  text:'View our corporate location by clicking link below',
  linkText:'location',
  link:'https://goo.gl/maps/1CCeLFmHG96qqiEo6'
  },
  {
  id:'2',
  iconSrc:require('../assets/src/icon/flaticon/location.png'),
  iconAlt:'location-icon',
  title:'factory location',
  text:'View our factory location by clicking link below',
  linkText:'location',
  link:'https://goo.gl/maps/GFtKrcWuXEf5hCMA8'
}
]
}  
]





export const dataDiscuss=[
  {
  id:'01',
  title:'35 T Cranes',
  text:'Capable to lift heavy weight',
  img: require('../assets/src/post/cranes.png'),
  imgAlt:'Cranes-img'
  },
  {
  id:'02',
  title:'Epoxy shop floor',
  text:' For Dust free ans anti-slip',
  img: require('../assets/src/post/floor.png'),
  imgAlt:'Floor-img'
  },
  {
  id:'03',
  title:'Painting booth',
  text:' For Dust free Painting',
  img: require('../assets/src/post/booth.png'),
  imgAlt:'Booth-img'
  },
]