import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {Box ,Button,Grid,Typography, Container } from '@mui/material';
import { dataForm } from '../../data/Contact';
import emailjs from '@emailjs/browser';
import useInput from '../../hooks/use-input';
import SuccessModal from '../../components/SuccessModal';

const TextFieldStyled = styled(TextField)(({theme}) => ({
    width:'85%!important',
    color:theme.palette.comman.main,
    "& .MuiFormLabel-root":{
     color:theme.palette.comman.main,
     "& .MuiFormLabel-asterisk":{
    color:theme.palette.secondary.main,
    marginLeft:theme.spacing(1),
     }
    },
    "& .MuiInputBase-root":{
      "&:before":{
        borderColor:theme.palette.text.main
      },
      "& .MuiInputBase-input":{
        color:theme.palette.comman.main,
      }
    }
   }));

const Form = () => {
  const form = useRef();
  const [submit,setSubmit] = useState(false);
  const {value:enteredFirstName,
    hasError:firstNameHasError,
    valueIsValid:firstNameIsValid,
    error:firstError,
    valueChangeHandler:firstNameChangeHandler,
    inputBlurHandler:firstNameBlurHandler,
    reset:firstNameReset} =  useInput(value=>value.trim()!=='');

  const {value:enteredLastName,
    hasError:lastNameHasError,
    valueIsValid:lastNameIsValid,
    error:lastError,
    valueChangeHandler:lastNameChangeHandler,
    inputBlurHandler:lastNameBlurHandler,
    reset:lastNameReset} =  useInput(value=>value.trim()!=='');
  const {value:enteredEmail,
    hasError:emailHasError,
    valueIsValid:emailIsValid,
    error:emailError,
    valueChangeHandler:emailChangeHandler,
    inputBlurHandler:emailBlurHandler,
    reset:emailReset} =  useInput(value=>value.includes('@'));

    let formIsValid = false;

    if(firstNameIsValid && lastNameIsValid && emailIsValid){
    formIsValid = true;
    }

  const sendEmail = (e) => {
    e.preventDefault();
    firstError();
    lastError();
    emailError();
    if(!firstNameIsValid && !lastNameIsValid && !emailIsValid){
      return;
  }
    firstNameReset();
    lastNameReset();
    emailReset();
    emailjs.sendForm('service_fyyzdgt', 'template_3pm6bds', form.current, 'GdMKZdG-ZNYcQFuEB')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setSubmit(true);
      setTimeout(function(){
        setSubmit(false);
      },4000);
      e.target.reset(); 
  };

  return (
    <React.Fragment>
      {submit && <SuccessModal />}
      {dataForm.map((item)=>
      <Grid container key={item.id}>
      <Grid item xs={12} md={5}>
      <img src={item.imgSrc} alt={item.imgAlt} width="100%" height="700px" />
      </Grid>
      <Grid item xs={12} md={7}>
      <Container >
      <Box component="form" ref={form} onSubmit={sendEmail} sx={{p:4,px:{xs:0,md:'inherit'}}}>
      <Typography variant='h2'>{item.title}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <TextFieldStyled id='First Name' onChange={firstNameChangeHandler} onBlur={firstNameBlurHandler} value={enteredFirstName} name="user_first" sx={{width:{xs:'100%',md:'auto'}}} label="First Name" variant="standard" color="comman" required/>
        {firstNameHasError && <Typography variant='body2' color="error">Name must not be empty</Typography>}
        </Grid>
        <Grid item xs={12} md={6}>
        <TextFieldStyled id='Last Name' onChange={lastNameChangeHandler} onBlur={lastNameBlurHandler} value={enteredLastName} name="user_last" sx={{width:{xs:'100%',md:'auto'}}} label="Last Name" variant="standard" color="comman" required/>
        {lastNameHasError && <Typography variant='body2' color="error">Name must not be empty</Typography>}
        </Grid>
        <Grid item xs={12} md={6}>
        <TextFieldStyled id='Email Address' onChange={emailChangeHandler} onBlur={emailBlurHandler} value={enteredEmail} name="user_email" sx={{width:{xs:'100%',md:'auto'}}} label="Email Address" variant="standard" color="comman" required/>
        {emailHasError && <Typography variant='body2' color="error">Name must not be empty</Typography>}
        </Grid>
        <Grid item xs={12} md={6}>
        <TextFieldStyled id='Company' name="user_company" sx={{width:{xs:'100%',md:'auto'}}} label="Company" variant="standard" color="comman"/>
        </Grid>
        <Grid item xs={12} md={6}>
        <TextFieldStyled id='Phone Number' name="user_number" sx={{width:{xs:'100%',md:'auto'}}} label="Phone Number" variant="standard" color="comman"/>
        </Grid>
        <Grid item xs={12} md={12}>
        <TextFieldStyled id='Message' name="user_message" sx={{width:{xs:'100%',md:'100%!important'}}} label="Message" variant="standard" color="comman"/>
        </Grid>
      </Grid>
      <Button color='primary' disabled={!formIsValid} type="submit" sx={{my:4,width:{xs:'100%',md:'auto'}}} variant='contained' size='large'>Send it</Button>
      </Box>
      </Container>
      </Grid>
      </Grid>
      )}
    </React.Fragment>
  )
}

export default Form