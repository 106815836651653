import React from 'react';
import { Container, Stack, Typography, Grid, Box } from '@mui/material'
import {Card, CardContent} from '@mui/material';
import { styled } from '@mui/material/styles';
import { dataOurTeam } from '../../../data/Teams';
import curve from '../../../assets/src/icon/curve.png';

const OurTeamBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.info.main,
}));

const OurTeam = () => {
  return (
    <React.Fragment>
    <Box style={{position:'relative'}}>
    <img src={curve} alt="line-img" className='curveLeft'/>
    <Box textAlign='center'>
    {dataOurTeam.map((item)=>
    <OurTeamBox key={item.id} sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Typography variant='h2' textAlign="center">
    {item.title}
    </Typography>
    <Grid container spacing={{xs:3,md:6}} justifyContent="center">
    {item.team.map((item,index)=>
    <Grid item xs={12} md={6} key={index}>
    <Card sx={{height:'100%',p:4}} className='cardStyle'>
    <CardContent sx={{p:0,pb:'0!important'}}>
    <Typography variant="h5" color="primary" mb={2}>
    {item.name}
    </Typography>
    <Typography variant="h5" mb={0}>
    {item.position}
    </Typography>
    </CardContent>
    </Card>
    </Grid>
    )}
    </Grid>
    </Container>
    </OurTeamBox>
    )}
    </Box>
    {/* <img src={curve} alt="line-img" className='curveRight'/> */}
    </Box>
    </React.Fragment>
  )
}

export default OurTeam