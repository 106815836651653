import React,{ useRef, useState } from 'react';
import {Box,Container,Typography,Grid, Stack, ListItem, List,Button} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import emailjs from '@emailjs/browser';
import useInput from '../hooks/use-input';
import SuccessModal from './SuccessModal';

const style = theme=>( {
    position: 'absolute',
    top:'0',
    left:'0',
    display:'grid',
    placeItems:'center',
    width: '100%',
    height:'100%',
    overflowY:'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
});

const TapData = [
  {
    name:'On Load',
  },
  {
    name:'Off Load',
  },
]

const tapRangeData=[
  {
    name:'On Load',
    tapValue:'+5% to -15% in 16 steps of 1.25%'
  },
  {
    name:'On Load',
    tapValue:'+10% to -10% in 16 steps of 1.25%'
  },
  {
    name:'On Load',
    tapValue:'+9.375% to -15.625% in 16 steps of 1.5625%'
  },
  {
    name:'On Load',
    tapValue:'+7% to -21% in 16 steps of 1.75%'
  },
  {
    name:'On Load',
    tapValue:'+10% to -22% in 16 steps of 2%'
  },
  {
    name:'Off Load',
    tapValue:'+5% to -10% in 6 steps of 2.5%'
  },
]


const RequestAQuote = (props) => {
  
  const form = useRef();
  const [submit,setSubmit] = useState(false);

  const {value:enteredName,
    hasError:nameHasError,
    valueIsValid:nameIsValid,
    valueChangeHandler:nameChangeHandler,
    inputBlurHandler:nameBlurHandler,
    reset:nameReset} =  useInput(value=>value.trim()!=='');

    const {value:enteredContactPerson,
      hasError:contactPersonHasError,
      valueIsValid:contactPersonIsValid,
      valueChangeHandler:contactPersonChangeHandler,
      inputBlurHandler:contactPersonBlurHandler,
      reset:contactPersonReset} =  useInput(value=>value.trim()!=='');

  const {value:enteredEmail,
    hasError:emailHasError,
    valueIsValid:emailIsValid,
    valueChangeHandler:emailChangeHandler,
    inputBlurHandler:emailBlurHandler,
    reset:emailReset} =  useInput(value=>value.includes('@'));

    const {value:enteredNumber,
      hasError:numberHasError,
      valueIsValid:numberIsValid,
      valueChangeHandler:numberChangeHandler,
      inputBlurHandler:numberBlurHandler,
      reset:numberReset} =  useInput(value=>value.trim()!=='');

    const {value:enteredKva,
      hasError:kvaHasError,
      valueIsValid:kvaIsValid,
      valueChangeHandler:kvaChangeHandler,
      inputBlurHandler:kvaBlurHandler,
      reset:kvaReset} =  useInput(value=>value.trim()!=='');

      // const {value:enteredHv,
      //   hasError:hvHasError,
      //   valueIsValid:hvIsValid,
      //   valueChangeHandler:hvChangeHandler,
      //   inputBlurHandler:hvBlurHandler,
      //   reset:hvReset} =  useInput(value=>value.trim()!=='');
        
        const {value:enteredLv,
          hasError:lvHasError,
          valueIsValid:lvIsValid,
          valueChangeHandler:lvChangeHandler,
          inputBlurHandler:lvBlurHandler,
          reset:lvReset} =  useInput(value=>value.trim()!=='');

    let formIsValid = false;

    if(nameIsValid && numberIsValid &&
       emailIsValid && kvaIsValid && contactPersonIsValid && lvIsValid){
    formIsValid = true;
    }

  const sendEmail = (e) => {
    e.preventDefault();
  
    nameReset();
    numberReset();
    emailReset();
    kvaReset();
    contactPersonReset();
    // hvReset();
    lvReset();
    setStd('');
    setEnergyefflevel('');
    setHv('');
    setHvTerminal('');
    setLvTerminal('');
    setTc('');
    setTRange('');
    emailjs.sendForm('service_qg5llq4', 'template_yp5e5e9', form.current, 'LiWPorrMeqBEAfxNZ')
      .then((result) => {
          console.log(result.text);
          // setSubmit(true);
      }, (error) => {
          console.log(error.text);
      });
      setSubmit(true);
      
      setTimeout(function(){
        setSubmit(false);
      },4000);
      e.target.reset(); 
      props.handleClose();
     
  };

  const [std, setStd] = React.useState('');
  const [energyefflevel, setEnergyefflevel] = React.useState('');
  const [hv, setHv] = React.useState('');
  const [hvTerminal, setHvTerminal] = React.useState('');
  const [lvTerminal, setLvTerminal] = React.useState('');
  const [tc, setTc] = React.useState('');
  const [tRange, setTRange] = React.useState('');
  const [tapRange, setTapRange] = React.useState([]);

  const stdHandleChange = (event) => {
    setStd(event.target.value);
    console.log(std);
  };
  const energyefflevelHandleChange = (event) => {
    setEnergyefflevel(event.target.value);
  };
  const hvHandleChange = (event) => {
    setHv(event.target.value);
  };
  const hvTerminalHandleChange = (event) => {
    setHvTerminal(event.target.value);
  };
  const lvTerminalHandleChange = (event) => {
    setLvTerminal(event.target.value);
  };
  const tcHandleChange = (event) => {
    setTc(event.target.value);
    const dt = tapRangeData.filter(tap => tap.name === event.target.value);
    setTapRange(dt);
    
  };
  const tRangeHandleChange = (event) => {
    setTRange(event.target.value);
  };
  return (
    <React.Fragment>
      {submit && <SuccessModal />}
    <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} p={{xs:'2rem',md:'2rem 10rem'}}>
        <Box sx={{position:'absolute',right:{xs:0,md:'5rem'},top:{xs:0,md:'1rem'}}} onClick={props.handleClose}>
        <IconButton size="large">
        <CancelIcon fontSize="inherit"/>
        </IconButton>
        </Box>
        
        <Box component="form" ref={form} onSubmit={sendEmail}>
          
        <Box>
          <Typography variant="h5" mb={4}>
          Customer details
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} md={6}>
            <TextField id="cname" onChange={nameChangeHandler} onBlur={nameBlurHandler} value={enteredName} name="user_name" label="Name" variant="outlined" size='small' required />
            {nameHasError && <Typography variant='body2' color="error">Name must not be empty</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField id="cp" onChange={contactPersonChangeHandler} onBlur={contactPersonBlurHandler} value={enteredContactPerson} name="user_contactPerson" label="ContactPerson" type="text" variant="outlined" size='small' required />
            {contactPersonHasError && <Typography variant='body2' color="error">ContactPerson must not be empty</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField id="mb" onChange={numberChangeHandler} onBlur={numberBlurHandler} value={enteredNumber} name="user_number" label="Mobile No" type="number" variant="outlined" size='small' required />
            {numberHasError && <Typography variant='body2' color="error">Number must not be empty</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField id="email" onChange={emailChangeHandler} onBlur={emailBlurHandler} value={enteredEmail} name="user_email" label="E-mail Id" type="email" variant="outlined" size='small' required />
            {emailHasError && <Typography variant='body2' color="error">Email must not be empty</Typography>}
            </Grid>
            <Grid item xs={12} md={12}>
            <TextField id="msge" label="Message" name="user_message" variant="outlined" size='small' multiline rows={4} />
            </Grid>
        </Grid>
        </Box>
          <Box>
          <Typography variant="h5">
            Transformer Details
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid item xs={12} md={6}>
          <TextField id="kva" onChange={kvaChangeHandler} onBlur={kvaBlurHandler} value={enteredKva} name="user_kva" label="KVA" variant="outlined" size='small' required />
          {kvaHasError && <Typography variant='body2' color="error">Kva must not be empty</Typography>}
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="std">Standard</InputLabel>
        <Select
        labelId="std"
        id="std"
        value={std}
        name="std"
        label="Standard"
        onChange={stdHandleChange}
        >
        <MenuItem value="IS 1180">IS 1180</MenuItem>
        <MenuItem value="IS 2026">IS 2026</MenuItem>
      </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="hv">HV</InputLabel>
        <Select
        labelId="hv"
        id="hv"
        value={hv}
        name="hv"
        label="hv"
        onChange={hvHandleChange}
        >
        <MenuItem value="11kv">11kv</MenuItem>
        <MenuItem value="22kv">22kv</MenuItem>
        <MenuItem value="33kv">33kv</MenuItem>
      </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="energyefflevel">Energy Efficiency Level</InputLabel>
        <Select
        labelId="energyefflevel"
        id="energyefflevel"
        value={energyefflevel}
        name="energyefflevel"
        label="Energy Efficiency Level"
        onChange={energyefflevelHandleChange}
        >
        <MenuItem value="Level 1 As per New Amd 04">Level 1 As per New Amd 04</MenuItem>
        <MenuItem value="Level 2 As per New Amd 04">Level 2 As per New Amd 04</MenuItem>
        <MenuItem value="Level 3 As per New Amd 04">Level 3 As per New Amd 04</MenuItem>
        <MenuItem value="Level 4 As per New Amd 04">Level 4 As per New Amd 04</MenuItem>
        <MenuItem value="Level 5 As per New Amd 04">Level 5 As per New Amd 04</MenuItem>
      </Select>
          </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
          <TextField id="lv" onChange={lvChangeHandler} onBlur={lvBlurHandler} value={enteredLv} name="user_Lv" label="LV" variant="outlined" size='small' required/>
          {lvHasError && <Typography variant='body2' color="error">Lv must not be empty</Typography>}
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="hvTerminal">HV Terminal</InputLabel>
        <Select
        labelId="hvTerminal"
        id="hvTerminal"
        value={hvTerminal}
        name="hvTerminal"
        label="hv Terminal"
        onChange={hvTerminalHandleChange}
        >
        <MenuItem value="Cable Box">Cable Box</MenuItem>
        <MenuItem value="Bare Bushing">Bare Bushing</MenuItem>
      </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="tc">Tap Changer</InputLabel>
        <Select
        labelId="tc"
        id="tc"
        value={tc}
        name="tc"
        label="Tap Changer"
        onChange={tcHandleChange}
        >
          {TapData.map((tap,index)=>
        <MenuItem key={index} value={tap.name}>{tap.name}</MenuItem>)}
       
        {/* <MenuItem value="On Load">On Load</MenuItem>
        <MenuItem value="Off Load">Off Load</MenuItem> */}
      </Select>
          </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="lvTerminal">LV Terminal</InputLabel>
        <Select
        labelId="lvTerminal"
        id="lvTerminal"
        value={lvTerminal}
        name="lvTerminal"
        label="lv Terminal"
        onChange={lvTerminalHandleChange}
        >
        <MenuItem value="Cable Box">Cable Box</MenuItem>
        <MenuItem value="Bare Bushing">Bare Bushing</MenuItem>
        <MenuItem value="Bus Duct">Bus Duct</MenuItem>
      </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl size="small">
        <InputLabel id="tRange">Tap Range</InputLabel>
        <Select
        labelId="tRange"
        id="tRange"
        value={tRange}
        label="Tap Range"
        onChange={tRangeHandleChange}
        >
          {tapRange.map(taps=>
        <MenuItem value={taps.tapValue}>{taps.tapValue}</MenuItem>)}
        {/* <MenuItem value={10}>+5% to -15% in 16 steps of 1.25%</MenuItem>
        <MenuItem value={20}>+10% to -10% in 16 steps of 1.25%</MenuItem>
        <MenuItem value={30}>+9.375% to -15.625% in 16 steps of 1.5625%</MenuItem>
        <MenuItem value={40}>+7% to -21% in 16 steps of 1.75%</MenuItem>
        <MenuItem value={50}>+10% to -22% in 16 steps of 2%</MenuItem> */}
      </Select>
          </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6} style={{display:'flex'}}>
          
          <Button variant='contained' color='primary' size='large' sx={{my:4,mt:'auto'}} type='submit' disabled={!formIsValid}>Send it</Button>
       
          </Grid>
        </Grid>
        </Box>
        
        </Box>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default RequestAQuote