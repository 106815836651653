import { useState } from "react";

const useInput= (validateValue) =>{
    const [enterValue,setEnterValue]=useState('');
    const [isTouched,setIsTouched]=useState(false);

    const valueIsValid = validateValue(enterValue);
    const hasError = !valueIsValid && isTouched;
    
    const valueChangeHandler = (event) =>{
      setEnterValue(event.target.value);
    }
  
    const inputBlurHandler = (event) =>{
      setIsTouched(true);
    } 
    const reset = () =>{
    setEnterValue('');
    setIsTouched(false);
    }
    const error = () =>{
    setIsTouched(true);
    }
   

return {
    value:enterValue,
    hasError,
    valueIsValid,
    error,
    valueChangeHandler,
    inputBlurHandler,
    reset,
}
}

export default useInput