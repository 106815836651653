import React from 'react';
import Hero from '../../components/Hero';
import Facility from './Facility';
import Testing from './Testing';
import Discuss from '../Policy&Credentials/Discuss';
import CallToAction from '../../components/CallToAction';
import { Button,Grid,Stack, Typography } from '@mui/material'
import { Box, Container } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';
import ServiceSupport from '../../components/ServiceSupport';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import Slider from "react-slick";
import { infraBannerData } from '../../data/Infra';
import arrow from '../../assets/src/icon/arrow.png';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const HeroBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.comman.main,
  display:'flex',
  alignItems:'center',
}));

const Infra = () => {

  const infraBanner = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    fade: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openService, setOpenService] = React.useState(false);
  const handleOpenService = () => setOpenService(true);
  const handleCloseService = () => setOpenService(false);
  return (
   
    <React.Fragment>
      <Slider {...infraBanner}>
    {infraBannerData.map((bannerImg,index) =>
    <>
    <Box key={index} sx={{position:'relative',background:'rgb(0, 0, 0,0.6);'}}>
    <img src={bannerImg.imgSrc} width='100%' style={{display:'block'}} />
    <Box className='overlaybg'></Box>
    <Container className='infra'>
    <HeroBox>
    <Grid container spacing={4} alignItems="center">
    <Grid item xs={12} md={8}>
    <Box>
    <Stack direction="row" spacing={3}>
    <img src={arrow} alt="arrow-img" style={{width:'30px',height:'30px',marginTop:'1.5rem'}}/>
    <Box>
    <Typography variant='h1'>
    Our factory & testing facilities ensure safety
    </Typography>
    <Link to='/contact'>
    <Button variant='contained' color='primary' size="large">Lets Connect</Button>
    </Link>
    </Box>
    </Stack>
    </Box>
    </Grid>
    
    </Grid>

    </HeroBox>
    </Container>
    </Box>
    </>
    )}
    </Slider>
    <Facility handleOpen={handleOpenService}/>  
     <Discuss />

    <Box sx={{marginTop:{xs:'-15rem',md:'-15rem'}}}>
    <CallToAction title="Got an interest lets discuss" text="We always guide before handling"
    buttonTextSecondary="Get a quote" onSecondaryClick={handleOpen}/>
    </Box>
    <Testing infraBanner={infraBanner}/> 
    <RequestAQuote open={open}  handleClose={handleClose} />
    <ServiceSupport open={openService}  handleClose={handleCloseService} />
    </React.Fragment>
  )
}

export default Infra