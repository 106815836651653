
export const dataHiring=[
  {
  id:'hiring',
  title:'Be part of our mission',
  subTitle:'we’re hiring !',
  text:'We’re looking for passionate people to join our mission we value flat hierarchies, clear communication and responsibilities',
  role:[
    {
      id:'1',
      title:'Maintenance Engineer',
      text:'We’re looking for maintenance engineer to join our team.',
      buttonTextPrimary:'coimbatore',
      variant:'contained',
    },
    {
      id:'2',
      title:'Senior Engineer Design - Electrical',
      text:'We’re looking for senior - level engineer design to join our team.',
      buttonTextPrimary:'chennai',
      variant:'contained',
    },
    {
      id:'3',
      title:'Senior Engineer Design - Mechanical',
      text:'We’re looking for senior - level engineer design to join our team.',
      buttonTextPrimary:'chennai',
      variant:'contained',
    },
    {
      id:'4',
      title:'Manager Design - Electrical',
      text:'We’re looking for manager design to join our team.',
      buttonTextPrimary:'chennai',
      variant:'contained',
    },
    {
      id:'5',
      title:'Manager Design - Mechanical',
      text:'We’re looking for manager design to join our team.',
      buttonTextPrimary:'chennai',
      variant:'contained',
    },
      
  ],

}  
]


export const dataBlock=[
  {
  id:'01',
  title:'We respect nature',
  text:'To avoid greenhouse effect',
  img: require('../assets/src/post/nature.png'),
  imgAlt:'Cranes-img'
  },
  {
  id:'02',
  title:'Heat extractor',
  text:'To protect workspace from heat',
  img: require('../assets/src/post/heatExtract.png'),
  imgAlt:'Floor-img'
  },
  {
  id:'03',
  title:'Canteen facilities',
  text:'Clean and hygiene canteens',
  img: require('../assets/src/post/canteen.png'),
  imgAlt:'Booth-img'
  },
]

export const dataInfo=[
    {
    id:'01',
    title:'About SPS',
    infoText:[
    {
    id:'1',
    text:'SPS Transformers Private Limited has been certified by TUV SUD South Asia Private Limited as an ISO 9001:2015 Certified Company which has emerged from a business house which, for 36 years, stamped it’s authority in the field of transformer making.',
    },
    {
    id:'2',
    text:'Working as a partner with our customers, our transformers serve every sector of the industry, right from Power to Petroleum to Pharmaceuticals, Steel to Sugar, Cement to Chemicals, Wind, Solar and Water Boards, thus joining hands with all those involved in nation-building. SPS transformers are designed to be robust and compact, to reduce losses, and operate in harsh environments.',
    },
    {
    id:'3',
    text:'Some key strategic initiatives have been made possible by the vision and hindsight of the second generation leadership at the helm of the company. SPS Transformers have successfully ventured into Design, Manufacture, Testing and supply of Distribution and Power Transformers.',
    }
    ]
    },
    {
    id:'02',
    title:'Who Should apply?',
    infoText:[
    {
    id:'1',
    text:'If you are excited about solving challenging technical problems in the big data space, Alexa.com offers an exceptional opportunity to work at the cutting edge of behavioral analytics on a global scale. We are seeking a smart, self-directed Senior Data Engineer to help us build the next generation of digital insights and audience analysis technology.',
    },
    ]
    },
    {
    id:'03',
    title:'What you will do?',
    infoLink:[
    {
    id:'1',
    text:'work on a disruptive product that’s still in its early stages',
    },
    {
    id:'2',
    text:'be responsible for the development and maintenance of the App aimed at ios mobile devices.',
    },
    {
    id:'3',
    text:'translate designs and wireframes into high-quality code',
    },
    {
    id:'4',
    text:'ensure the best possible performance, quality, and responsiveness of the  App',
    },
    {
    id:'5',
    text:'mentor other engineers, define our technical culture and help to build a fast-growing team.',
    },
    ]
    },
    {
    id:'04',
    title:'You should apply if you:',
    infoLink:[
    {
    id:'1',
    text:'possess 6+ years of experience in product development, architecture, and design',
    },
    {
    id:'2',
    text:'experienced in swift, or have a strong desire to shift to swift',
    },
    {
    id:'3',
    text:'strong understanding of algorithms',
    },
    {
    id:'4',
    text:'have strong knowledge of iOS frameworks, different versions of iOS, and know how to deal with different screen sizes',
    },
    {
    id:'5',
    text:'have experience with storage, threading, and performance tuning',
    },
    {
    id:'6',
    text:'strong knowledge of iOS architectural components and design patterns',
    },
    {
    id:'7',
    text:'are good in pixel-perfect implementation of designs',
    },
    {
    id:'8',
    text:'deep understanding of memory management, multi-threaded programming, and synchronization',
    },
    {
    id:'9',
    text:'strong debugging and root cause analysis skills alongside data structures',
    },
    {
    id:'10',
    text:'Familiarity with designing and developing Core OS frameworks',
    },
    {
    id:'11',
    text:'mentor and support other team members by doing code reviews and apply your experience in process and technical leadership',
    },
    {
    id:'12',
    text:'work in agile team environments and are a self-starter, conscientious member of the team.',
    },
    ]
    },
]