export const imgGallery=[
{
    id:'1',
    imgSrc:require('../assets/src/product/product1.png'),
},
{
    id:'2',
    imgSrc:require('../assets/src/product/product2.png'),
},
{
    id:'3',
    imgSrc:require('../assets/src/product/product3.png'),
},
{
    id:'4',
    imgSrc:require('../assets/src/product/product4.png'),
},
{
    id:'5',
    imgSrc:require('../assets/src/product/product5.png'),
},
{
    id:'6',
    imgSrc:require('../assets/src/product/product6.png'),
},
{
    id:'7',
    imgSrc:require('../assets/src/product/product7.png'),
},
{
    id:'8',
    imgSrc:require('../assets/src/product/product8.png'),
},
{
    id:'9',
    imgSrc:require('../assets/src/product/product9.png'),
},
// {
//     id:'10',
//     imgSrc:require('../assets/src/product/product10.png'),
// },
{
    id:'11',
    imgSrc:require('../assets/src/product/product11.png'),
},
{
    id:'12',
    imgSrc:require('../assets/src/product/product12.png'),
},
{
    id:'13',
    imgSrc:require('../assets/src/product/product13.png'),
},
{
    id:'14',
    imgSrc:require('../assets/src/product/product14.png'),
},
{
    id:'15',
    imgSrc:require('../assets/src/product/product15.png'),
},
// {
//     id:'16',
//     imgSrc:require('../assets/src/product/product16.png'),
// },
{
    id:'17',
    imgSrc:require('../assets/src/product/product17.png'),
},
{
    id:'18',
    imgSrc:require('../assets/src/product/product18.png'),
},
{
    id:'19',
    imgSrc:require('../assets/src/product/product19.png'),
},
// {
//     id:'20',
//     imgSrc:require('../assets/src/product/product20.png'),
// },
]

export const infraGallery=[
{
    id:'1',
    imgSrc:require('../assets/src/infra/infra1.png'),
},
{
    id:'2',
    imgSrc:require('../assets/src/infra/infra2.png'),
},
// {
//     id:'3',
//     imgSrc:require('../assets/src/infra/infra3.png'),
// },
// {
//     id:'4',
//     imgSrc:require('../assets/src/infra/infra4.png'),
// },
{
    id:'5',
    imgSrc:require('../assets/src/infra/infra5.png'),
},
{
    id:'6',
    imgSrc:require('../assets/src/infra/infra6.png'),
},
{
    id:'7',
    imgSrc:require('../assets/src/infra/infra7.png'),
},
{
    id:'8',
    imgSrc:require('../assets/src/infra/infra8.png'),
},
{
    id:'9',
    imgSrc:require('../assets/src/infra/infra9.png'),
},
{
    id:'10',
    imgSrc:require('../assets/src/infra/infra10.png'),
},
{
    id:'11',
    imgSrc:require('../assets/src/infra/infra11.png'),
},
{
    id:'12',
    imgSrc:require('../assets/src/infra/infra12.png'),
},
{
    id:'13',
    imgSrc:require('../assets/src/infra/infra13.png'),
},
{
    id:'14',
    imgSrc:require('../assets/src/infra/infra14.png'),
},
{
    id:'15',
    imgSrc:require('../assets/src/infra/infra15.png'),
},
{
    id:'16',
    imgSrc:require('../assets/src/infra/infra16.png'),
},
{
    id:'17',
    imgSrc:require('../assets/src/infra/infra17.png'),
},
{
    id:'18',
    imgSrc:require('../assets/src/infra/infra18.png'),
},
{
    id:'19',
    imgSrc:require('../assets/src/infra/infra19.png'),
},
{
    id:'20',
    imgSrc:require('../assets/src/infra/infra20.png'),
},
{
    id:'21',
    imgSrc:require('../assets/src/infra/infra21.png'),
},
{
    id:'22',
    imgSrc:require('../assets/src/infra/infra22.png'),
},
{
    id:'23',
    imgSrc:require('../assets/src/infra/infra23.png'),
},
// {
//     id:'24',
//     imgSrc:require('../assets/src/infra/infra24.png'),
// },
]