export const Downloads =[
{
    id:'1',
    title:'BIS License & Certification',
    buttonText:'View',
    imgUrl:require('../assets/src/certificate/bisLicense.png'),
    file:'Bis_License_&_Certification.png'
},
{
    id:'2',
    title:'NSIC Certificate',
    buttonText:'View',
    imgUrl:require('../assets/src/certificate/nsicCertificate.png'),
    file:'NSIC_Certificate.png'
},
{
    id:'3',
    title:'ISO Certificate',
    buttonText:'View',
    imgUrl:require('../assets/src/certificate/isoCertificate.png'),
    file:'ISOCERTIFICATE.png'
},
{
    id:'4',
    title:'Brochure - Distribution Transformers',
    buttonText:'View',
    imgUrl:require('../assets/src/certificate/transformersBrochure.png'),
    file:'Broucher.png'
},
{
    id:'5',
    title:'Brochure - Distribution Solar',
    buttonText:'View',
    imgUrl:require('../assets/src/certificate/solarBrochure.png'),
    file:'Broucher.png'
},


]