import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Box, Grid, Stack, Container } from '@mui/material';
import {List,ListItem,ListItemIcon} from '@mui/material';
import {dataCredentials} from '../../data/PolicyCredentials';
import BoltIcon from '@mui/icons-material/Bolt';
import bisCertificate from '../../assets/src/certificate/bisCertificate.jpg'
import Modals from '../../ui/Modals';

const CredentialsBox = styled(Box)(({theme}) => ({
    boxShadow: '0px 9px 45px rgba(0, 0, 0, 0.09)',
    borderRadius:theme.spacing(2)
  }));

const Credentials = () => {
  const [credential,setCredential] = useState(false);

  const credentialHandler = () =>{
    setCredential(prev => !prev);
  }
  const credentialCloseHandler = () =>{
    setCredential(false);
  }
  return (
    <React.Fragment>
    <Container>
    <CredentialsBox sx={{my:{xs:3,md:6,lg:8},p:{xs:4,md:8}}}>
    {dataCredentials.map((item,index)=>
    <Grid container columnSpacing={10} rowSpacing={6} key={item.id}>
  <Grid item xs={12} md={12}>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <List>
    {item.credential.map((item)=>
      <ListItem key={item.id}>
        <BoltIcon color="secondary" />
      <Typography variant='body1' color='text.main' mb={4}>{item.text}</Typography>
      </ListItem>
    )}
  </List>
  <Button variant={item.variant} color='primary' size="large" onClick={credentialHandler}>{item.buttonText}</Button>
  </Grid>
  </Grid> )}
  </CredentialsBox>
  </Container>
  {credential && <Box>
 <Modals onClose={credentialCloseHandler} title="BIS Certificate">
 <img src={bisCertificate} width='100%' />
 </Modals>
    </Box>}
  
    </React.Fragment>
  )
}

export default Credentials