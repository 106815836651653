import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Box, Container, List, ListItem  } from '@mui/material';
import {Card, CardMedia, CardContent, CardActions} from '@mui/material';
import Grid from '@mui/material/Grid';
import auxiliary from '../../assets/src/product/auxiliary.jpg';
import { dataProduct,productImgData } from '../../data/ProductServices';
import Slider from "react-slick";
import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';

const FacilityBox = styled(CardMedia)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.comman.main,
  backgroundColor:theme.palette.primary.dark,
  height:'auto',
  margin:0,
  paddingTop:theme.spacing(10),
  paddingBottom:theme.spacing(10),
}));

const ProductBox = styled(Box)(({theme}) => ({
}));

const Product = (props) => {
  const handleOpen = useOutletContext();
    
  return (
    <React.Fragment>
      {dataProduct.map((item,index)=>
    <>
      <FacilityBox sx={{mb:{xs:3,md:6,lg:8}}} image={item.imgSrc}
        title={item.imgAlt} key={item.id}>
    <Container>
    <Grid container columnSpacing={20} rowSpacing={4}>
    <Grid item xs={12} md={6}>
    <Typography variant='h6' color='primary'>
    {item.subTitle}
    </Typography>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    {item.textList.map((textList,index)=>
    <Typography variant='body1' mb={4} key={index}>
    {textList.text}
    </Typography>
     )}
    <Link to='#'>
    <Button variant={item.variant} size="large" onClick={props.handleOpen}>{item.buttonText}</Button>
    </Link>
    </Grid>
    <Grid item xs={12} md={6}>
    
    <List>
    {item.list.map((item,index)=>
    <ListItem key={item.id}>
      <BoltIcon color="secondary" />
    <Typography variant='body1' mb={3}>
    {item.text}
    </Typography>
    </ListItem>  
      )}     
    </List>
   
    </Grid>
    
    </Grid>
    </Container>
    </FacilityBox>
    </>)}
    <ProductBox className='product' sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Box>
    <Typography variant='h2'>
    Some of the highlights of our product line include
    </Typography>
    <Grid container spacing={4}>
      {productImgData.map((img,index)=>
       <Grid item xs={12} md={4} key={index}>
       <Card sx={{textAlign:"center",bgcolor:'transparent',boxShadow:'none'}}>
         <img src={img.imgSrc} width="100%" style={{borderRadius:'16px'}}/>
         </Card>
         </Grid>
      )}
      </Grid>
    </Box>
    </Container>
    </ProductBox>
    </React.Fragment>
  )
}

export default Product