export const infraBannerData=[
  {
  id:'1',
  imgSrc:require('../assets/src/infra/infraBanner1.jpg'),
  },
  {
  id:'2',
  imgSrc:require('../assets/src/infra/infrabanner2.jpg'),
  },
  {
  id:'3',
  imgSrc:require('../assets/src/infra/infraBanner3.jpg'),
  },
  {
  id:'4',
  imgSrc:require('../assets/src/infra/infraBanner4.jpg'),
  }
]


export const dataFacility=[
  {
  id:'facility',
  title:'Factory facilities we have',
  subTitle:'What we have',
  text:'Phase II project is to expand and build up capacity to manufacture Transformers up to 50MVA, 132kV Class.',
  buttonText:'Service Support',
  variant:'contained',
  list:[
    {
      id:'1',
      text:'5.4 acres of land',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'2',
      text:'40,000 square feet dust free Epoxy floored Shop',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'3',
      text:'35 T crane to lift biggest size transformer easily',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'4',
      text:'Main Bay 74 m long X 15 m wide, 7 m wide',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'5',
      text:'Separate winding shop, 56 m X 12 m',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'6',
      text:'Autoclave for drying',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'7',
      text:'Paint booth to ensure dust free painting',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
  ],

}  
]

export const dataTesting=[
  {
  id:'facility',
  title:'Testing Facilities',
  text:'Our testing facilities are well equipped with system to generate the test reports, Our all high end & calibrated testing equipments like high voltage tester, resistance meter, ratio meter & megger are used for routine tests to be performed in-house.',
  buttonText:'Lets Connect',
  variant:'contained',
  link:'/contact',
  list:[
    {
      id:'1',
      text:'Ratio test',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'2',
      text:'Resistance test',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'3',
      text:'Polarity & Phase relation test',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'4',
      text:'Load Loss & No Load Loss measurement',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'5',
      text:'Excitation current',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'6',
      text:'Applied Voltage',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'7',
      text:'Induced Voltage',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'8',
      text:'Temperature rise',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'9',
      text:'Test on transformer oil',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
  ],

}  
]

export const testingImg = [
  {
  id:'1',
  imgSrc:require('../assets/src/testing/testing1.jpg'),
  },
  {
  id:'2',
  imgSrc:require('../assets/src/testing/testing2.jpg'),
  },
  {
  id:'3',
  imgSrc:require('../assets/src/testing/testing3.jpg'),
  },
  {
  id:'4',
  imgSrc:require('../assets/src/testing/testing4.jpg'),
  },
  {
  id:'5',
  imgSrc:require('../assets/src/testing/testing5.jpg'),
  },
]