import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography,Button, Container, Box, List, ListItem } from '@mui/material';
import { dataFacility } from '../../data/Infra';
import factory from '../../assets/src/product/factory.jpg'
import { Link } from 'react-router-dom';
import BoltIcon from '@mui/icons-material/Bolt';

const FacilityBox = styled(CardMedia)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.comman.main,
    backgroundColor:theme.palette.primary.dark,
    height:'auto',
    margin:0,
    paddingTop:theme.spacing(10),
    paddingBottom:theme.spacing(20),
  }));

const FacilityImg = styled(CardMedia)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.comman.main,
    textTransform:'capitalize',
    height:'350px',
    width:'90%',
    marginTop:theme.spacing(-22),
    marginLeft:'auto',
    marginRight:'auto',
    borderRadius:theme.spacing(2),
    marginBottom:theme.spacing(8)
  }));

const Facility = (props) => {
  return (
    <React.Fragment>
    {dataFacility.map((item,index)=>
    <>
      <FacilityBox sx={{mb:{xs:3,md:6,lg:8}}} image={item.imgSrc}
        title={item.imgAlt} key={item.id}>
    <Container>
    <Grid container columnSpacing={20} rowSpacing={4}>
    <Grid item xs={12} md={6}>
    <Typography variant='h6' color='primary'>
    {item.subTitle}
    </Typography>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <Typography variant='body1' mb={4}>
    {item.text}
    </Typography>
    <Link to='#'>
    <Button variant={item.variant} size="large" onClick={props.handleOpen}>{item.buttonText}</Button>
    </Link>
    </Grid>
    <Grid item xs={12} md={6}>
    
    <List>
    {item.list.map((item,index)=>
    <ListItem key={item.id}>
      <BoltIcon color="secondary" />
    <Typography variant='body1' mb={3}>
    {item.text}
    </Typography>
    </ListItem>  
      )}     
    </List>
   
    </Grid>
    
    </Grid>
    </Container>
    </FacilityBox>

    <Box>
    <Container>
    <FacilityImg image={factory} alt="factory-img" />
    </Container>
    </Box>
    </>
    )}
    </React.Fragment>
  )
}

export default Facility