import { Box, Container } from '@mui/material';
import React from 'react';
import loading from '../assets/src/icon/lottie/loading.gif';

const Loading = () => {
  return (
    <React.Fragment>
    <Container>
    <Box sx={{textAlign:'center',p:4}}>
    <img src={loading} alt="loading" />
    </Box>
    </Container>
    </React.Fragment>
  )
}

export default Loading