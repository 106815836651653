import { Box, Container,Typography } from '@mui/material';
import React from 'react';
import electricityLoading from '../assets/src/icon/lottie/electricityLoading.gif';


const PreLoader = () => {
  return (
    <React.Fragment>
   
    <Box className="preLoader" sx={{textAlign:'center',p:4}}>
    <Container>
    <img src={electricityLoading} alt="electricityLoading-gif" width="100px" height="100px" style={{marginBottom:'1rem'}}/>
    <Typography variant="h2" mb={3}>
    Power industry is in fast track mode<br/>to catch - up the left - outpace
      </Typography>
    </Container>
    </Box>
    </React.Fragment>
  )
}

export default PreLoader