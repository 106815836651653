import React from 'react';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import {Card,CardContent,CardActions,CardMedia} from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { dataDiscuss } from '../../data/PolicyCredentials';

const DiscussBox = styled(Box)(({theme}) => ({
    backgroundColor:theme.palette.primary.light,
    color:theme.palette.comman.main,
    paddingBottom:theme.spacing(20),
  }));

const Discuss = () => {
  return (
    <React.Fragment>
    <DiscussBox sx={{my:{xs:3,md:6,lg:8}}}>
    <Grid container>
    {dataDiscuss.map((item)=>
    <Grid item xs={12} md={4} key={item.id}>
    <Card sx={{textAlign:"center",bgcolor:'transparent',boxShadow:'none'}}>
      {/* <CardMedia
        sx={{ height: 250 }}
        image={item.img}
        title={item.imgAlt}
        alt={item.imgAlt}
      /> */}
      <img src={item.img} width="100%" />
      <CardContent>
      <Avatar sx={{mb:2,bgcolor:'primary.main',mx:'auto'}}>{item.id}</Avatar>
        <Typography variant="h5" color="comman.main">
        {item.title}
        </Typography>
        <Typography variant="body2" color="text.main">
        {item.text}
        </Typography>
      </CardContent>
    </Card>
    </Grid>
    )}
    </Grid>
    </DiscussBox>
    </React.Fragment>
  )
}

export default Discuss