import { createTheme } from "@mui/material";

export const theme=createTheme({
    spacing: 8,
    shape:{
    borderRadius:8
    },
    // shadows:{
    // // 1:'0px 9px 45px rgba(131, 197, 245, 0.08)',
    // // 2:'0px 9px 45px rgba(0, 0, 0, 0.08)'
    // },
    palette:{
        primary:{
        // main:'linear-gradient(92.44deg, #2388EF 37.1%, #0C5BAB 104.9%);',
        main:'#4086ef',
        dark:'#02083F',
        // dark:'red',
        // main:'#02083F',
        // dark:'#4086ef',
        light:'#040B2A',
        // light:'#0052a4',
    
        },
        secondary:{
        main:'#ff4949'
        },
        info:{
        main:'#F2F6FB'
        },
        text:{
        main:'#6A6B71',
        
        },
        comman:{
        main:'#ffffff',
        },
        neutral:{
        main:'#ffffff',
        },


    },
    typography:{
        fontFamily: "'Inter', sans-serif",
    h1:{
        fontSize:'3.052rem',
        fontWeight:700,
        letterSpacing:'normal',
        lineHeight:1.6,
        marginBottom:'2rem'
    },
    h2:{
        fontSize:'2.441rem',
        fontWeight:700,
        marginBottom:'3rem'
    },
    h3:{
        fontSize:'1.953rem',
        fontWeight:600,
        marginBottom:'1.5rem'
    },
    h4:{
        fontSize:'1.563rem',
        fontWeight:700,
        marginBottom:'1.5rem'
    },
    h5:{
        fontSize:'1.25rem',
        fontWeight:600,
        marginBottom:'1.5rem'
    },
    h6:{
        fontSize:'1rem',
        fontWeight:600,
        marginBottom:'1.5rem'
    },
    body2:{
        fontSize:'0.875rem',
    },
    body1:{
        marginBottom:'1.5rem'
    }
    },
    components:{
        MuiButton:{
        styleOverrides:{
        root:{
        borderRadius:'24px',
        }   
        }
        },
        containedPrimary:{
        styleOverrides:{
        root:{
        backgroundColor:'red'
        }   
        }
        },
        MuiFormControl:{
        styleOverrides:{
        root:{
            width:'100%'
        }
        }
        },
        MuiList:{
        styleOverrides:{  
        root:{
        padding:'0',
        }
        }   
        },
        MuiTabs:{
        styleOverrides:{  
        root:{
        position:'sticky',
        top:'90px'
        }   
        }
        },
        MuiListItem:{
        styleOverrides:{  
        root:{
        padding:'0rem',
        gap:'1rem',
        alignItems:'start',
        }
        }
        },
        MuiButtonBase:{
        styleOverrides:{
        root:{
        textTransform:'capitalize!important'
        }
        }  
        },
    }
})