import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Box, Grid, Stack, Container } from '@mui/material';
import {List,ListItem,ListItemIcon} from '@mui/material';
import {dataCredentials} from '../../data/PolicyCredentials';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { dataHiring } from '../../data/Carrier';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';

const HiringBox = styled(Box)(({theme}) => ({
    
  }));

const Hiring = () => {
  return (
    <React.Fragment>
    {dataHiring.map((item)=>
    <HiringBox key={item.id} sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Grid container>
    <Grid item xs={12} md={6}>
     <Typography variant='h6' color="primary">
     {item.subTitle}
    </Typography>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <Typography variant='body1' color='text.main' mb={4}>
    {item.text}
    </Typography>
    </Grid>
    </Grid>
    {item.role.map((item)=>
    
    <Stack key={item.id} py={4} sx={{borderTop:'1px solid',borderColor:(theme)=>theme.palette.text.main}}
    direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="flex-start" useFlexGap spacing={2}>
    <Box>
    <Typography variant='h4'>
    {item.title}
    </Typography>
    <Typography variant='body1'>
    {item.text}
    </Typography>
    <Stack spacing={4} direction="row" useFlexGap alignItems='center'>
    <Stack spacing={1} direction="row" useFlexGap>
    <EmailIcon />
    <Typography mb={0} sx={{textTransform:'initial'}}>Send resumes to <Link to="mailto:mktg@spstransformers.com" className='active'>hr@spstransformers.com</Link></Typography>
    </Stack>
    </Stack>
    </Box>

    <Box>
    <Button variant={item.variant} size="medium" startIcon={<LocationOnIcon />}>
    {item.buttonTextPrimary}
    </Button>
    </Box>
    </Stack>
    )}

    </Container>
    </HiringBox>
     )}
    </React.Fragment>
  )
}

export default Hiring