import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Button from './Button';

const BackDrop = (props)=>{
    return(
    // (props)to pass data through attributes
    <div className="backdrop" onClick={props.onClose}/>
)
}

const ModalOverLay = (props) =>{
    return(
    // (children props) to pass the data btw opening/closing tag 
    <div className="modal">
    <div className='modal_header'>
    <h5>{props.title}</h5>
    <div onClick={props.onClose}>
    <i class="fa-solid fa-xmark"></i>
    </div>
    </div>
    <div className='modal_body'>
    {props.children}
    </div>
    </div>
    )
}


const Modals = (props) => {
    const portalElement = document.getElementById("wrap");
  return (
    <React.Fragment>
    {ReactDOM.createPortal(<BackDrop onClose={props.onClose}/>,portalElement)}
    {ReactDOM.createPortal(<ModalOverLay title={props.title} buttonText={props.buttonText} buttonClassName={props.buttonClassName} onClose={props.onClose}>
        {props.children}</ModalOverLay>,portalElement)}
    </React.Fragment>
  )
}

export default Modals