import React from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './pages/Root';
import Home from './pages/Home/Home';
import Error from './states/Error';
import './css/global.css';
import { Team } from './pages/About/Team/Team';
import Download from './pages/News&Media/Download/Download';
import Engineering from './pages/Engineering/Engineering';
// import OverView from './pages/About/OverView/OverView';
import ProductServices from './pages/Product&Services/ProductServices';
import PolicyCredentials from './pages/Policy&Credentials/PolicyCredentials';
import Contact from './pages/Contact/Contact';
import Carrier from './pages/Carrier/Carrier';
import Info from './pages/Carrier/Info/Info';
import Infra from './pages/Infra/Infra';
import Gallery from './pages/News&Media/Gallery/Gallery';
import MoreClients from './pages/MoreClients/MoreClients';
import Loading from './components/Loading';

const OverView = React.lazy(() => import('./pages/About/OverView/OverView'));


const router= createBrowserRouter([
  {path:'/',element:<Root />,
  // errorElement:<Error />,
  children:[
  {index:true,element:<Home />},
  {path:'about/overview',element:<React.Suspense fallback= {<Loading />} > <OverView /></React.Suspense>},
  {path:'about/team',element:<Team />},
  {path:'about/infra',element:<Infra />},
  {path:'about/carrier',element:<Carrier />},
  {path:'about/carrier/info',element:<Info />},
  {path:'about/clients',element:<MoreClients />},
  {path:'newsmedia/downloads',element:<Download />},
  {path:'newsmedia/gallery',element:<Gallery />},
  {path:'engineering',element:<Engineering />},
  {path:'contact',element:<Contact />},
  {path:'/productservices',element:<ProductServices />},
  {path:'/policycredentials',element:<PolicyCredentials />},
  
]
},

])

function App() {
  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
