import React, { useEffect, useState } from 'react'
import Header from '../layouts/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../layouts/Footer'
import RequestAQuote from '../components/RequestAQuote'
import ServiceSupport from '../components/ServiceSupports'
import PreLoader from '../components/PreLoader';


const Root = () => { 
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },3000)
  },[])
  return (
    <React.Fragment>
      {loading? <PreLoader />
      :
      <>
    <Header />
    <Outlet />
    <Footer />
    </>
  
  }
   
    </React.Fragment>
  )
}

export default Root