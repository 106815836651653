import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import {Card, CardMedia, CardContent, CardActions} from '@mui/material';
import { dataServices } from '../../data/ProductServices';
import arrow from '../../assets/src/icon/arrow.png';

const ServicesBox = styled(Box)(({theme}) => ({
  }));

const Services = () => {
  return (
    <React.Fragment>
    <ServicesBox sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Box sx={{textAlign:'center'}}>
    <Typography variant='h2'>
    The services  we provide
    </Typography>
    <Typography variant='body1' color='text.main' mb={6}>
    How we respond
    </Typography>
    </Box>
    <Box>
    <Grid container spacing={{xs:4,md:8}}>
    {dataServices.map((service,index)=>{
    return(
    <Grid item xs={12} md={6} key={service.id}>
    <Card sx={{height:'100%'}}>
    <CardContent sx={{p:6}}>
        <Typography variant="h5" sx={{display:'flex',justifyContent:'space-between'}}>
          {service.title}
          <img src={arrow} alt="arrow-img" style={{width:'25px',height:'25px'}}/>
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {service.text}
        </Typography>
      </CardContent>
    </Card>
    </Grid> 
        )
    })}
    </Grid>
    </Box>
    </Container>
    </ServicesBox>
    </React.Fragment>
  )
}

export default Services