import React from 'react';
import Hero from '../../components/Hero'
import Factory from './Factory';
import Quality from './Quality';
import Credentials from './Credentials';
import Discuss from './Discuss';
import CallToAction from '../../components/CallToAction';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const PolicyCredentials = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
    <Hero title="Our policies ensure healthy work Environment"
    className="policies"
    buttonText="Lets connect"
    link="/contact"
    color="primary"
    variant="contained"
    />
    <Factory handleOpen={handleOpen} />
    <Quality />
    <Discuss />

    <Box sx={{marginTop:{xs:'-15rem',md:'-15rem'}}}>
    <CallToAction title="Got an interest lets discuss" text="We always guide before handling"
    buttonTextSecondary="Get a quote" onSecondaryClick={handleOpen} />
    </Box>
    
   <Credentials />
   <RequestAQuote open={open}  handleClose={handleClose} />
    </React.Fragment>
  )
}

export default PolicyCredentials