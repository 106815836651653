import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, Grid,Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import { dataClients } from '../../data/Home';
import { Link } from 'react-router-dom';

const ClientBox = styled(Box)(({theme}) => ({
  
}));

const Client = () => {

  const clients = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
         arrows : false,
        }
      },
    ]
  };
  return (
    <React.Fragment>
    <ClientBox sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Typography variant='h2' textAlign='center' className='hrHalf'>Clients</Typography>
    <Box sx={{py:3}}>
        <Slider {...clients}>
        {dataClients.map((item)=>
        
        <Box key={item.id} sx={{p:2}}>
        <img src={item.imgSrc} style={{width:'100%',marginLeft:'auto',marginRight:'auto'}} />
      </Box>
        )}
        </Slider>
    </Box>
    <Box sx={{textAlign:'center'}}>
    <Link to="/about/clients">
      <Button size="large" color="primary" variant="outlined">View More</Button>
      </Link>
      </Box>
    </Container>
    </ClientBox>
    </React.Fragment>
  )
}

export default Client