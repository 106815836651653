import React,{ useState } from 'react';
import {Typography, Card, Box, Grid} from '@mui/material';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea, CardActions } from '@mui/material';
import pdfScanning from '../../../assets/src/icon/lottie/pdfScanning.gif';
import { styled } from '@mui/material/styles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Modals from '../../../ui/Modals';

const CardBox = styled(Card)(({theme}) => ({
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'start',
    gap:theme.spacing(5),
    padding:theme.spacing(5),
    boxShadow: '0px 9px 45px rgba(0, 0, 0, 0.08)',
    borderRadius:'1rem',
    height:'100%',
  }));

const DownloadBox = styled(Box)(({theme}) => ({

}))

const Certification = (props) => {
  const [certificate,setCertificate] = useState(false);

  const certificateHandler = (imgUrl) =>{
    // setCertificate(prev => !prev);
    setCertificate(imgUrl);
  }
  const certificateCloseHandler = () =>{
    setCertificate(false);
  }
  return (
    <React.Fragment>
    <DownloadBox sx={{py:{xs:4,md:8,lg:8}}}>
    <Grid container spacing={{xs:4,md:8}}>
        {props.download.map((download)=>(
        <Grid key={download.id} item xs={12} sm={6} md={6}>
        <CardBox>
      <img src={pdfScanning} alt='pdf-img' className='gifScanning' width='120px' style={{borderRadius:'1rem'}} />
      <CardContent sx={{p:'0'}}>
        <Typography variant="h4">
         {download.title}
        </Typography>
        <Button variant="contained" color='primary' onClick={() => certificateHandler(download.imgUrl)}>{download.buttonText}<KeyboardDoubleArrowRightIcon /></Button>    
      </CardContent>
         </CardBox>
         <Box>
    </Box>
      </Grid>
      ))}
        
      </Grid>
      </DownloadBox>
      
      {certificate && <Box>
    <Modals onClose={certificateCloseHandler} title="Certificate">
    <img src={certificate} width='100%' />
    </Modals>
    </Box>}
    </React.Fragment>
  )
}

export default Certification

