import React from 'react';
import logo from '../assets/src/logo/logo.JPG';
import { about, news } from '../data/Header';
import {Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import {Box,Container,Typography,Grid, Stack, ListItem, List,Button} from '@mui/material';
import {Card} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import classes from  './Header.module.css'
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SPS from '../assets/src/logo/SPS.jpg';
import ISO from '../assets/src/logo/ISO.png';
import RequestAQuote from '../components/RequestAQuote';

const Header = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  }
  let boxClass = [""];

  if(isOpen) {
    boxClass.push('show');
  }else{
    boxClass.push('');
}


  return (
   <React.Fragment>
<AppBar position="sticky" color='comman' sx={{padding:'0.5rem 0'}}>
  <Container>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Link to="/">
    <Stack direction='row'>
    <img src={SPS} alt="logo" style={{width:'7rem'}}/>
    <img src={ISO} alt="logo" style={{width:'7rem'}} />
    </Stack>
    </Link>
    <Box sx={{display:{xs:'block',md:'none'}}} onClick={toggleHandler}>
    <IconButton size="large" color='primary'>
    <MenuIcon fontSize="inherit" sx={{width:'1.5em',height:'1.5em',borderRadius:'2px',border:'1px solid',borderColor:(theme)=>theme.palette.primary.main}} />
    </IconButton>
    </Box>
    <List className={boxClass.join(' ')} sx={{display:{xs:'none',md:'flex'},alignItems:'center',p:0,flexDirection:{xs:'column',md:'row'},position:{xs:'absolute',md:'static',}}}>
    <ListItem sx={{width:'auto',fontWeight:500,p:2,display:{xs:'flex',md:'none',},alignItems:'center',justifyContent:'space-between'}}>
    <Link to="/" onClick={toggleHandler}>
    <Stack direction='row'>
    <img src={SPS} alt="logo" style={{width:'7rem'}} />
    <img src={ISO} alt="logo" style={{width:'7rem'}} />
    </Stack>
    </Link>
    <IconButton size="large" sx={{color:(theme)=>theme.palette.primary.dark}} onClick={toggleHandler}>
    <CloseIcon fontSize="inherit" sx={{width:'1.5em',height:'1.5em'}}/>
    </IconButton>
    </ListItem>
    <ListItem className='navLink' sx={{width:'auto',fontWeight:500,p:3}}>
      <NavLink to='/' onClick={toggleHandler}>
        <Typography className='navList' variant='body1'>
        Home
        </Typography>
      </NavLink>
      </ListItem>
        <ListItem className="dropdownMenu navLink" sx={{width:'auto',p:3}}>
        <Typography className='navList' variant='body1'>
          About<ArrowDropDownIcon />
          </Typography>
          <Card className='dropdownList' sx={{padding:2,minWidth:'600px'}}>
          <Grid container spacing={2}>
            {about.map((item)=>
            <Grid item xs={12} md={6} key={item.title}>
            <NavLink to={item.url} onClick={toggleHandler}>
            <Stack direction="row" spacing={2} p={2} className={classes.navSubList}>
            <i className={item.icon} style={{marginTop:'8px'}}></i>
            <Box>
            <Typography variant='h6' mb={0}>{item.title}</Typography>
            <Typography variant='caption' color="text.main" sx={{lineHeight:1}}>{item.text}</Typography>
            </Box>
            </Stack>
            </NavLink>
          </Grid>
            )}
              
          </Grid> 
          </Card>
        </ListItem>
        <ListItem className="dropdownMenu navLink" sx={{width:'auto',p:3}}>
        <Typography className='navList' variant='body1'>
        News & Media<ArrowDropDownIcon />
          </Typography>
          <Card className='dropdownList' sx={{padding:2,minWidth:'600px'}}>
          <Grid container spacing={2}>
            {news.map((item)=>
            <Grid item xs={12} md={6} key={item.title}>
            <NavLink to={item.url} onClick={toggleHandler}>
            <Stack direction="row" spacing={2} p={2} className={classes.navSubList}>
            <i className={item.icon} style={{marginTop:'8px'}}></i>
            <Box>
            <Typography variant='h6' mb={0}>{item.title}</Typography>
            <Typography variant='caption' color="text.main" sx={{lineHeight:1}}>{item.text}</Typography>
            </Box>
            </Stack>
            </NavLink>
          </Grid>
            )}
              
          </Grid> 
          </Card>
        </ListItem>
        <ListItem className='navLink' sx={{width:'auto',fontWeight:500,p:3}}>
      <NavLink to='/engineering' onClick={toggleHandler}>
        <Typography className='navList' variant='body1'>
        Engineering
        </Typography>
      </NavLink>
      </ListItem>
      <ListItem className='navLink' sx={{width:'auto',fontWeight:500,p:3}}>
      <NavLink to='/contact' onClick={toggleHandler}>
      <Typography className='navList' variant='body1'>
        Contact
        </Typography>
      </NavLink>
      </ListItem>
      <Button variant="contained" size='large' sx={{ml:{xs:2,md:3},mt:{xs:2,md:0},bgcolor:(theme)=>theme.palette.primary.dark}} onClick={handleOpen}>Request a quote</Button>
      </List>
    </Stack>
    <RequestAQuote open={open}  handleClose={handleClose} />
  </Container>
</AppBar>





{/* <header className="stickyTop">
<div className="container">
<nav className="nav">
  <div className="container-fluid">
    <Link className="nav_logo" to="/">
    <img src={logo} alt="logo" className="nav_logo--img" />
    </Link>
    <div className="nav_toggler" onClick={navtoggler}>
    {istoggler?<i class="fa-solid fa-xmark"></i>:
    <i className="fa-solid fa-bars"></i>}</div>
   
    <ul className={istoggler?'nav_menu active':'nav_menu'}>
      {menuItems && menuItems.map((items,index)=>(
        <li className="nav_item" key={index}>
        <NavLink className={({isActive})=>isActive?"nav_item--link active":"nav_item--link"} to={items.url}>
          {items.title}<i className={items.icon}></i></NavLink>
        <ul className="dropdown">
              {items.SubMenuItems && items.SubMenuItems.map((subitems,index)=>(
              <li className="dropdown_item" key={index}>
              <NavLink className="dropdown_item--link" to={subitems.url}>
              <i className={subitems.icon}></i>
              <div>
              <p className="strong">{subitems.title}</p>
              <small className="small">{subitems.text}</small></div>
              </NavLink>
            </li>
              ))}
          </ul> 
        </li>
      ))}
      <Button className="btn btn-primary" type="button" onClick={handleOpen}>Request a quote<i class="fa-solid fa-arrow-right"></i><i class="fa-brands fa-superpowers"></i></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Transformer Details
          </Typography>

          <Grid  spacing={4}>
        <Grid xs={12} md={6}>
        <TextField
          label="KVA*"
          id="outlined-size-small"
          color="primary"
          size="small"
          width='100%'
        />
        </Grid>
        <Grid xs={12} md={6}>
        <TextField
          label="KVA*"
          id="outlined-size-small"
          color="primary"
          size="small"
          width='100%'
        />
        </Grid>
      </Grid>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
     
      </ul>
  </div>
</nav>
</div>
</header> */}
  {/* {cartModal&&
  <Modal onClose={closeCartHandler} title="Book a Demo" buttonText="Book a Demo" buttonClassName="btn-primary">
    <Form/>
    </Modal>} */}
   </React.Fragment>
  )
}

export default Header