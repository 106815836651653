import { Typography,Stack,Box } from '@mui/material'
import React from 'react'
import { theme } from '../theme'
import { styled } from '@mui/material/styles';
import tick from '../assets/src/icon/lottie/tick.gif';

const SuccessBox = styled(Box)(({theme})=>({
    alignItems: 'center',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    bottom: 0,
    justifyContent: 'center',
    zIndex: 1500,
    background: 'rgba(0, 0, 0, 0.7)',
}))
   


const SuccessModal = () => {
  return (
    <React.Fragment>
    <SuccessBox>
    <Stack sx={{alignItems:'center',color:(theme)=>theme.palette.primary.dark,bgcolor:(theme)=>theme.palette.comman.main,borderRadius:2,padding:(theme)=>theme.spacing(4)}}>
    <img src={tick} alt="tick" width="100px" />
    <Typography variant='h5'>Thank You!</Typography>
    <Typography variant='body1'>
    Your Submission has been sent.
    </Typography>
    </Stack>
    </SuccessBox>
    </React.Fragment>
  )
}

export default SuccessModal