import React from 'react';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { styled } from '@mui/material/styles';
import arrowGif from '../../../assets/src/icon/lottie/arrow.gif';
import { Box, Card, CardContent, Container, Stack, Typography } from '@mui/material'

const NewsUpdateBox = styled(Box)(({theme}) => ({
    paddingTop:theme.spacing(3),
    paddingBottom:theme.spacing(3),
  }));

const DataUpdate = (props) => {
  return (
    <React.Fragment>
     <NewsUpdateBox>
    <Container>
    <Card >
    <CardContent sx={{p:{xs:2,md:4}}}>
    <Stack direction="row" useFlexGap spacing={2}>
    <Typography variant='h6' color="primary">
    News & Updates
    </Typography>
    <FeedOutlinedIcon color='primary'/>
    </Stack>
    <Stack direction="row" alignItems="center">
    <marquee behavior="scroll" direction="up" scrollamount="1" style={{height:'30px'}} >
    <Typography variant='body2' mb={0} color="primary.dark">
  
    The product range manufactured and supplied by SPS Transformers caters to all the needs of Power & Distribution.We offer comprehensive maintenance programs that define maintenance work and the required spare parts for different stages.
    </Typography></marquee>
    <img src={arrowGif} alt="" style={{width:'30px'}} />
    </Stack>
    </CardContent>
    </Card>
    </Container>
    </NewsUpdateBox>
    </React.Fragment>
  )
}

export default DataUpdate