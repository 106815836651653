import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {List,ListItem,ListItemIcon} from '@mui/material';
import Grid from '@mui/material/Grid';
import { dataManu, workerData } from '../../data/Engineerings';
import process from '../../assets/src/icon/lottie/process.gif';
import gears from '../../assets/src/icon/lottie/gears.gif';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';
import Slider from "react-slick";
import BoltIcon from '@mui/icons-material/Bolt';


const ManufacturingBox = styled(Box)(({theme}) => ({
  backgroundColor:theme.palette.primary.dark,
  color:theme.palette.comman.main,
 
}));


const Manufacturing = (props) => {
  // const handleOpen = useOutletContext();
  const manuImg = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    // speed: 500,
    // autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
   <React.Fragment>
    <ManufacturingBox sx={{py:{xs:8,md:10}}}>
    <Container>
    {dataManu.map((item)=>
    <Grid container rowSpacing={6} columnSpacing={20} key={item.id}>
  <Grid item xs={12} md={6}>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <List>
      {item.info.map((item)=>
      <ListItem key={item.id}>
        {/* <img src={item.imgSrc} alt={item.imgAlt} style={{width:'25px',height:'25px'}}/> */}
        <BoltIcon color="secondary" />
      <Typography variant='body1' mb={4}>{item.text}</Typography>
      </ListItem>
      )}
  </List>
  <Button variant="contained" color='primary' size="large" onClick={props.handleOpen}>Request a quote</Button>
  </Grid>
  <Grid item xs={12} md={6}>
  <List>
    {item.list.map((card)=>
    <ListItem key={card.id} sx={{backgroundColor:'comman.main',p:2,borderRadius:'1rem',mb:3,width:'fit-content',gap:2}}>
        <img src={card.imgSrc} alt={card.imgAlt} width="20px"/>
        <Typography variant='h6' mb={0} color='primary.dark'>
        {card.title} 
        </Typography>
      </ListItem>
      )}
      {/* <ListItem p={0}> */}
      <Box sx={{position:'relative'}}>
      <Slider {...manuImg}>
      {workerData.map((workerImg,index)=>
      <div key={index} >
      <img src={workerImg.imgSrc} alt="worker-img"  style={{width:'100%',display:'block',borderRadius:'1rem',border:'2px solid white'}}/>
      </div>
      )}
      </Slider>
      <img src={process} className='xs-hide' alt="process-gif" style={{width:'70px',position:'absolute',left:'-25px',bottom:'-25px',borderRadius:'1rem'}} />
      <img src={gears}  className='xs-hide' alt="gears.gif" style={{width:'70px',height:'70px',position:'absolute',right:'-25px',top:'-25px',borderRadius:'1rem'}} />
      </Box>
      {/* </ListItem> */}
  </List>
    
  </Grid>
  </Grid>
   )}
    </Container>
    </ManufacturingBox>
   
   </React.Fragment>
  )
}

export default Manufacturing