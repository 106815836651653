import React from 'react'
import Hero from '../../components/Hero'
import Product from './Product'
import Services from './Services'
import CallToAction from '../../components/CallToAction'
import { Typography, Button, Box, Grid, Container,Stack, } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote'
import ServiceSupport from '../../components/ServiceSupport'
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import Slider from "react-slick";
import { productBannerData } from '../../data/ProductServices';
import arrow from '../../assets/src/icon/arrow.png';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const HeroBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.comman.main,
  display:'flex',
  alignItems:'center',
}));

const ProductServices = () => {
  const productBanner = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    fade: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openService, setOpenService] = React.useState(false);
  const handleOpenService = () => setOpenService(true);
  const handleCloseService = () => setOpenService(false);
  return (
    <React.Fragment>
      <Slider {...productBanner}>
    {productBannerData.map((bannerImg) =>
    <>
    <Box sx={{position:'relative',background:'rgb(0, 0, 0,0.6);'}}>
    <Box>
    <img src={bannerImg.imgSrc} width='100%' style={{display:'block'}} />
    </Box>
    <Box className='overlaybg'></Box>
    <Container className='infra'>
    <HeroBox>
    <Grid container spacing={4} alignItems="center">
    <Grid item xs={12} md={8}>
    <Box>
    <Stack direction="row" spacing={3}>
    <img src={arrow} alt="arrow-img" style={{width:'30px',height:'30px',marginTop:'1.5rem'}}/>
    <Box>
    <Typography variant='h1'>
    The design is well equipped to meet all customer requirements
    </Typography>
    <Link to='/contact'>
    <Button variant='contained' color='primary' size="large">Lets Connect</Button>
    </Link>
    </Box>
    </Stack>
    </Box>
    </Grid>
    
    </Grid>

    </HeroBox>
    </Container>
    {/* <Container sx={{position:'absolute',top:'0'}}>
    <Hero title="the design is well equipped to meet all customer requirements"
    className="product"
    link="/contact"
    md='8'
    buttonText="Lets connect"
    variant="contained"
    color='primary'
    />
    </Container> */}
    </Box>
    </>
    )}
    </Slider>
    
    <Product handleOpen={handleOpen} />
    <Services />
    <CallToAction title="Service Support" text="Please contact us with the provided contact details and also fill in the enquiry form available if you need Service Support for your purchase or product related enquiries."
    buttonTextPrimary="Request a Quote" buttonTextSecondary="Service Support" onPrimaryClick={handleOpen} onSecondaryClick={handleOpenService} />
    <Box sx={{backgroundColor:'primary.light',height:'400px',marginTop:'-300px'}}></Box>
    <RequestAQuote open={open}  handleClose={handleClose} />
    <ServiceSupport open={openService}  handleClose={handleCloseService} />
    </React.Fragment>
  )
}

export default ProductServices