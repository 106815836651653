import { Grid, Typography,Box,Container,Stack, Button, List, ListItem, ListItemIcon } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { dataInfo } from '../../../data/Carrier';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const ContentBox = styled(Box)(({theme}) => ({

  }));

const Content = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <React.Fragment>
    <ContentBox sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Grid container>
    <Grid item xs={12} md={10}>
    <Link to="/about/carrier">
    <Stack mb={4} direction="row" useFlexGap spacing={1} sx={{color:(theme)=>theme.palette.primary.main}}>
    <KeyboardDoubleArrowLeftIcon />
    Back to job
    </Stack></Link>
    {dataInfo.map((item)=>
    <Box sx={{mb:4}} key={item.id}>
    <Typography variant='h4'>{item.title}</Typography>
    {item.infoText && item.infoText.map((item)=>
    <Typography variant='body1' key={item.id}>{item.text}</Typography>
    )}
    {item.infoLink && item.infoLink.map((item)=>
    <List key={item.id}>
    <ListItem>
    <ListItemIcon sx={{minWidth:'auto'}}>
        <CheckCircleIcon color='success' />
        </ListItemIcon>
    <Typography variant='body1'>{item.text}</Typography>
    </ListItem>
    </List>
    )}
    </Box>
     )}
     <Button variant='outlined'>Apply to this job</Button>
    </Grid>
    </Grid>
    </Container>
    </ContentBox>
    </React.Fragment>
  )
}

export default Content