import React from 'react';
import {Box ,Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Form from './Form';
import Location from './Location';
import Address from './Address';
import RequestAQuote from '../../components/RequestAQuote';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';


const ContactBox = styled(Box)(({theme}) => ({
 backgroundColor:theme.palette.primary.dark,
 color:theme.palette.comman.main,
 "&.MuiInputLabel-root":{
  color:theme.palette.comman.main,
 }
}));



const Contact = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
   <React.Fragment>
    <ContactBox>
    <Form />
    <Location handleOpen={handleOpen} />
    <Address />
    </ContactBox>
    <RequestAQuote open={open}  handleClose={handleClose} />
   </React.Fragment>
  )
}

export default Contact