import React from 'react';
import { dataInnovations } from '../../data/Home';
import { Container, Typography, Box, Stack,Grid} from '@mui/material';
import Slider from "react-slick";
import {Card,CardActions,CardContent,CardMedia} from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import electricityLoading from '../../assets/src/icon/lottie/electricityLoading.gif';
import { Link } from 'react-router-dom';
import ourProducts from '../../assets/src/innovation/ourProducts.png'
import services from '../../assets/src/innovation/services.png'
import engineering from '../../assets/src/innovation/engineering.png'

const InnovationBox = styled(Box)(({theme}) => ({
   
  }));

const Innovation = () => {

    const innovation = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <React.Fragment>
      {dataInnovations.map((item)=>
    <InnovationBox sx={{py:{xs:3,md:6,lg:8}}} key={item.id}>
    <Container>
    <Stack direction="row" spacing={4} useFlexGap alignItems="center">
    <Typography variant='h2' className='titleBar'>{item.title}</Typography>
    <img src={electricityLoading} alt="electricityLoading-gif" width="50px" height="50px" style={{marginTop:'1rem',alignSelf:'start'}}/>
    </Stack>
    <Box>
    <Slider {...innovation}>
    <Card sx={{p:4,m:1,width:'auto!important'}} className='zoomInHover'>
    <Box style={{overflow:'hidden'}} mb={3}>
    <img src={ourProducts} alt="innovation-img" className='zoomInEffect' style={{width:'100%'}}/>
    </Box>
    <CardContent sx={{p:0}}>
      <Typography variant="h4">
      Our Products
      </Typography>
      <Typography variant="body1" color="text.main">
      The product range manufactured and supplied by SPS Transformers caters to all the needs of Power & Distribution.
      </Typography>
      <br/>
    </CardContent>
    <CardActions sx={{p:0}}>
    <Link to="/productservices">
      <Button size="large" color="primary" variant="outlined">Read More</Button>
      </Link>
    </CardActions>
  </Card>
    <Card sx={{p:4,m:1,width:'auto!important'}} className='zoomInHover'>
    <Box style={{overflow:'hidden'}} mb={3}>
    <img src={services} alt="innovation-img" className='zoomInEffect' style={{width:'100%'}}/>
    </Box>
    <CardContent sx={{p:0}}>
      <Typography variant="h4">
      Services
      </Typography>
      <Typography variant="body1" color="text.main">
      We offer comprehensive maintenance programs that define maintenance work and the required spare parts for different stages.
      </Typography>
    </CardContent>
    <CardActions sx={{p:0}}>
    <Link to="/productservices">
      <Button size="large" color="primary" variant="outlined">Read More</Button>
      </Link>
    </CardActions>
  </Card>
    <Card sx={{p:4,m:1,width:'auto!important'}} className='zoomInHover'>
    <Box style={{overflow:'hidden'}} mb={3}>
    <img src={engineering} alt="innovation-img" className='zoomInEffect' style={{width:'100%'}}/>
    </Box>
    <CardContent sx={{p:0}}>
      <Typography variant="h4">
      Engineering
      </Typography>
      <Typography variant="body1" color="text.main">
      Backed by a strong team of Design & Engineering experts with rich experience of over 3 decades, SPS gives their customers world class products.
      </Typography>
    </CardContent>
    <CardActions sx={{p:0}}>
    <Link to="/engineering">
      <Button size="large" color="primary" variant="outlined">Read More</Button>
      </Link>
    </CardActions>
  </Card>
    </Slider>
    </Box>
    </Container>
    </InnovationBox>
     )}
    </React.Fragment>
  )
}

export default Innovation