import React from 'react'
import Hero from '../../components/Hero';
import transformer from '../../assets/src/post/transformer.png';
import DesignProcess from './DesignProcess';
import Manufacturing from './Manufacturing';
import RequestAQuote from '../../components/RequestAQuote';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const Engineering = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
    <Hero title="Our trained engineering design team ensure quality"
    className="engineering"
    buttonText="Lets Connect"
    color="primary"
    link="/contact"
    variant="contained"
    />
    <Manufacturing handleOpen={handleOpen} />
    <DesignProcess />
    <RequestAQuote open={open}  handleClose={handleClose} />
    </React.Fragment>
  )
}

export default Engineering