import React from 'react';
import { useState } from 'react';
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import {Container,Grid,Box,Stack,Typography,Button,CardMedia} from '@mui/material';
import { styled } from '@mui/material/styles';
import arrow from '../../assets/src/icon/arrow.png';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { dataHero } from '../../data/Home';

const HeroBox = styled(CardMedia)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.comman.main,
    textTransform:'capitalize',
    width:'100%',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  const innovation = {
    dots: true,
    infinite: true,
    autoplay:true,
    autoplaySpeed:2000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

const HeroHome = () => {

  return (
    <React.Fragment>
      <Slider {...innovation}>
      {dataHero.map((item)=>
        <HeroBox key={item.id} className='hero' image={item.imgSrc} title={item.imgAlt} style={{cursor:'grab'}}>
        <Box py={{xs:8,md:16}} sx={{backgroundImage:'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',height:'100%',width:'100%',display:'flex',alignItems:'center'}}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
          <Box>
          <Stack direction="row" spacing={3}>
          <img src={arrow} alt="arrow-img" style={{width:'30px',height:'30px',marginTop:'1.5rem'}}/>
          <Box>
          <Typography variant='h1' sx={{textTransform:'uppercase'}}>
          {item.title}
          </Typography>
          <Typography variant='body1'>
          {item.text}
          </Typography>
          </Box>
          </Stack>
          </Box>
          </Grid>
          </Grid></Container>
          </Box>
          </HeroBox>
      )}
      </Slider>
    </React.Fragment>
  )
}

export default HeroHome