export const menuItems=[
{
    title:'Home',
    url:'',
},
{
    title:'About',
    icon:'fa-solid fa-caret-down',
    subMenuItems:[
    {
    title:'Overview',
    url:'about/overview',
    icon:'fa-brands fa-rocketchat',
    text:'We Believe In Invention Is The Most Important Product'
    },
    {
    title:'Team',
    url:'about/team',
    icon:'fa-solid fa-user-plus',
    text:'Our Pillar Of Guide And Support, Who Help Us In Every Endeavour'
    },
    {
    title:'Infra',
    url:'about/infra',
    icon:'fa-solid fa-user-plus',
    text:'Our Factory & Testing Facilities Ensure Safety'
    },
    {
    title:'Careers',
    url:'about/carrier',
    icon:'fa-solid fa-user-plus',
    text:'Join Our Team ,We’ll Figure Out If You’re A Good Fit!'
    },
    {
    title:'Policy & Credentials',
    url:'policycredentials',
    icon:'fa-solid fa-user-plus',
    text:'Our Policies Ensure Healthy Work Environment'
    },
    {
    title:'Product & Services',
    url:'productservices',
    icon:'fa-solid fa-user-plus',
    text:'Our Products Priorities Safety Above Creativity'
    },
    ]
},
{
    title:'News & Media',
    icon:'fa-solid fa-caret-down',
    url:'newsmedia/downloads',
    subMenuItems:[
    {
    title:'Gallery',
    url:'newsmedia/gallery',
    icon:'fa-solid fa-images',
    text:'Automate your business with QS Business Platform'
    },
    {
    title:'Downloads',
    url:'newsmedia/downloads',
    icon:'fa-solid fa-user-plus',
    text:'Automate your business with QS Business Platform'
    },
    ]
},
{
    title:'Engineering',
    url:'engineering',
},
{
    title:'Contact',
    url:'contact',
},

]

export const news=[
{
    title:'Gallery',
    url:'newsmedia/gallery',
    icon:'fa-solid fa-images',
    text:'Our Infrastructure & Our Products'
    },
    {
    title:'Downloads',
    url:'newsmedia/downloads',
    icon:'fa-solid fa-download',
    text:'Dowloads our Certification'
    },
]

export const about=[
{
    title:'Overview',
    url:'about/overview',
    icon:'fa-brands fa-rocketchat',
    text:'We Believe In Invention Is The Most Important Product'
    },
    {
    title:'Team',
    url:'about/team',
    icon:'fa-solid fa-user-plus',
    text:'Our Pillar Of Guide And Support, Who Help Us In Every Endeavour'
    },
    {
    title:'Infra',
    url:'about/infra',
    icon:'fa-solid fa-globe',
    text:'Our Factory & Testing Facilities Ensure Safety'
    },
    {
    title:'Careers',
    url:'about/carrier',
    icon:'fa-solid fa-laptop-file',
    text:'Join Our Team ,We’ll Figure Out If You’re A Good Fit!'
    },
    {
    title:'Policy & Credentials',
    url:'policycredentials',
    icon:'fa-solid fa-user-shield',
    text:'Our Policies Ensure Healthy Work Environment'
    },
    {
    title:'Product & Services',
    url:'productservices',
    icon:'fa-brands fa-servicestack',
    text:'Our Products Priorities Safety Above Creativity'
    },
    {
    title:'Clients',
    url:'about/clients',
    icon:'fa-solid fa-heart-circle-bolt',
    text:'Our Valued Clients Inspire Us To Craft Products'
    },
]
