import { Container, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Hero from '../../../components/Hero'
import Certification from './Certification';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import { useLocation } from "react-router-dom";
import { Downloads } from "../../../data/Downloads";

const Download = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
    <Hero title="Downloads"
    className="download"
    buttonText="Contact us"
    color="primary"
    link="/contact"
    variant="contained"
    />

    <Container>
    <Certification download={Downloads} />

    </Container>

    </React.Fragment>
  )
}

export default Download