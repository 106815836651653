import React from 'react';
import { Container, Typography, Box, Grid} from '@mui/material';
import {Card,CardActions,CardContent,CardMedia} from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import quality from '../../assets/src/post/quality.png';
import { Link } from 'react-router-dom';

const QualityBox = styled(Box)(({theme}) => ({
   
  }));

const QualityPolicy = () => {
  return (
    <React.Fragment>
        <QualityBox sx={{py:{xs:3,md:6,lg:8}}}>
        <Container>
        <Grid container alignItems='center' sx={{flexDirection:'row-reverse'}} rowSpacing={4}>
        <Grid item xs={12} md={8}>
      <Box style={{overflow:'hidden',borderRadius:'24px'}}>
      <img src={quality} title="sps_transformer" alt="sps_transformer" className='zoomImgHover' style={{width:'100%',display:'block'}} />
      </Box>
      </Grid>
      <Grid item xs={12} md={4}>
    <Card sx={{padding:'3rem 2rem',borderRadius:3,mr:{xs:'auto',md:-15},zIndex:2,position:'relative',mt:{xs:-23,md:'auto'},maxWidth:{xs:'90%',md:'fit-content'},mx:'auto'}}>
    <CardContent sx={{p:0}}>
      <Typography variant="h2" mb={3}>
      Quality Policy
      </Typography>
      <Typography variant="body1" color="text.main" mb={3}>
      We at SPS Transformers Private Limited are committed to Designing, Manufacturing & Supplying of Oil Cooled Power and Distribution Transformer with the best of Quality, On-Time delivery to our Customer Satisfaction.
      </Typography>
    </CardContent>
    <CardActions sx={{p:0}}>
      <Link to="/policycredentials">
      <Button size="large" color="primary" variant='outlined'>Learn more</Button>
      </Link>
    </CardActions>
  </Card>
  </Grid>
  </Grid>
  </Container>
  </QualityBox>
    </React.Fragment>
  )
}

export default QualityPolicy