import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material'
import React from 'react'
import Box from '@mui/material/Box';
import Hero from '../../../components/Hero';
import Director from './OurTeam';
import OurTeam from './OurTeam';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const TeamBox = styled(Box)(({theme}) => ({
 
}));

export const Team = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <React.Fragment>
    <TeamBox>
    <Hero title="The people that make it happen"
    text="Our pillar of guide and support, who help us in every endeavour"
    className="team"
    buttonText="Contact us"
    color="primary"
    variant="contained"
    link="/contact"
    /> 
    <OurTeam />
    </TeamBox>
    </React.Fragment>
  )
}
