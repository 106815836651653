import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import curve from '../assets/src/icon/curve.png';

const CardBox = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  ...theme.typography.body1,
  position:'relative',
  zIndex:2,
  textAlign:'center',
  color: theme.palette.comman.main,
  borderRadius:theme.spacing(4)
}));

const CallToAction = (props) => {

  return (
    <React.Fragment>
  <Container>
    <CardBox sx={{my:{xs:3,md:6,lg:8},p:{xs:4,md:14},px:{md:20}}}>
      <img src={curve} alt="line-img" className='curveLeft'/>
      <CardContent sx={{p:0}}>
        <Typography variant="h2" mb={3}>
        {props.title}
        </Typography>
        <Typography className='fw-500' variant="body1" sx={{mb:{xs:2,md:4},maxWidth:{xs:'100%',md:'80%'},mx:'auto'}}>
        {props.text}
        </Typography>
      </CardContent>
      <CardActions sx={{justifyContent:"center",p:0}}>
      <Stack direction={{xs:'column',md:'row'}} spacing={{xs:2,md:6}} useFlexGap >
  
    <Button style={{display:props.buttonTextPrimary?'block':'none'}} variant="contained" color="primary" size='large' onClick={props.onPrimaryClick}>{props.buttonTextPrimary}</Button>
    
    <Button style={{display:props.buttonTextSecondary?'block':'none',zIndex:1000}}  variant="outlined" color="comman" size='large' onClick={props.onSecondaryClick}>{props.buttonTextSecondary}</Button>
    
    </Stack>
      </CardActions>
      <img src={curve} alt="line-img" className='curveRight'/>
    </CardBox>
    </Container>
    </React.Fragment>
  )
}

export default CallToAction