import React from 'react';
import { Container, Stack, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import videoEngineering from '../../assets/src/video/videoEngineering.mp4';
import { Link } from 'react-router-dom';

const DesignBox = styled(Box)(({theme}) => ({
    textAlign:'center',
}));

const DesignProcess = () => {
  
  return (
    <React.Fragment>
    <DesignBox sx={{py:{xs:8,md:10}}}>
    <Container>
    <Typography variant='h2' sx={{maxWidth:'75%',mx:'auto',textAlign:'center'}}>
    Electrical design process,allowing designers to create and refine designs
    </Typography>
    <Typography variant='body1' sx={{mb:{xs:2,md:4},maxWidth:{xs:'100%',md:'80%'},mx:'auto'}}>
    Backed by a strong team of Design & Engineering experts with 4 DECADES of rich experience in transformer making, SPS gives their customers world class products which cater to all the needs of Power & Distribution.
    </Typography>
    <Link to="/contact">
    <Button variant="contained" color='primary' size="large">lets connect</Button>
    </Link>
    <Box sx={{mt:6}}>
    <video width="100%" height="400px" className='video_tag' controls autoPlay>
    <source src={videoEngineering} type='video/mp4'></source>
    </video>
    </Box>
    </Container>
    </DesignBox>
    </React.Fragment>
  )
}

export default DesignProcess