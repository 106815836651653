import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import corporate from '../../assets/src/infra/corporate.png';
import factory from '../../assets/src/infra/factory.png'

const AddressBox = styled(Box)(({theme}) => ({
    backgroundColor:theme.palette.primary.light,
    color:theme.palette.comman.main,
  }));

const Address = () => {
  return (
    <React.Fragment>
    <AddressBox sx={{py:{xs:8,md:10}}}>
    <Container>
    <Grid container spacing={{xs:2,md:6}}>
    <Grid item xs={12} md={6} sx={{borderRight:{xs:'none',md:'1px solid #6A6B71'}}}>
    <Stack direction={{xs:'column-reverse',md:'row'}} spacing={{xs:2}} useFlexGap sx={{flexWrap:{xs:'wrap',md:'nowrap'},textAlign:{xs:'center',md:'left'}}}>
    <Box>
    <Typography variant='h4'>
    Corporate Office
    </Typography>
    <Typography variant='body2' mb={2} color="text.main">
    9:00Am - 5:00pm 
    </Typography>
    <Typography variant='body1' component="address" sx={{fontStyle:'normal'}}>No:1 ,Dr.B.N. Road,1st Street,T-Nagar,
    Chennai–600017.</Typography>
  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap:'wrap',justifyContent:{xs:'center',md:'start'}}}>
  <Typography variant='body2' color="text.main">044-43324022</Typography>
  <Typography variant='body2' color="text.main">044-43325022</Typography>
  </Stack>
    </Box>
    <Box>
    <img src={corporate} alt="corporate-img" style={{width:'200px'}} />
    </Box>
    </Stack>
    
    </Grid>
    <Grid item xs={12} md={6}>
    <Stack direction={{xs:'column-reverse',md:'row'}} spacing={{xs:2}} useFlexGap sx={{flexWrap:{xs:'wrap',md:'nowrap'},textAlign:{xs:'center',md:'left'}}}>
    <Box>
    <Typography variant='h4'>
    Factory
    </Typography>
    <Typography variant='body2' mb={2} color="text.main">
    9:00Am - 5:00pm 
    </Typography>
    <Typography variant='body1' component="address" sx={{fontStyle:'normal'}}>  Survey No.192, SNMV College Road,
  Malumichampatty,Coimbatore – 641050</Typography>
  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap:'wrap',justifyContent:{xs:'center',md:'start'}}}>
  <Typography variant='body2' color="text.main">+91 9384663033</Typography>
  <Typography variant='body2' color="text.main">+91 9384663042</Typography>
  <Typography variant='body2' color="text.main">+91 9884112176</Typography>
  </Stack>
    </Box>
    <Box>
    <img src={factory} alt="corporate-img" style={{width:'200px'}} />
    </Box>
    </Stack>
    
    </Grid>
   
    </Grid>
    </Container>
    </AddressBox>
    </React.Fragment>
  )
}

export default Address