export const productBannerData=[
    {
    id:'1',
    imgSrc:require('../assets/src/hero/productBanner1.jpg'),
    },
    {
    id:'2',
    imgSrc:require('../assets/src/hero/productBanner2.jpg'),
    }
  ]


export const dataProduct=[
  {
  id:'facility',
  title:'Product we produce',
  textList:[
    {
    id:'1',
    text:'The product range manufactured and supplied by SPS Transformers caters to all the needs of Power & Distribution Transformers ranging from 100kVA to 16MVA upto 132kV class.',
    },
    {
    id:'1',
    text:'Some of the highlights of our product line include',
    },
],
  buttonText:'Service Support',
  variant:'contained',
  list:[
    {
      id:'1',
      text:'Distribution Transformer as per BIS Energy level Standards',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'2',
      text:'Power Transformer (Upto 16 MVA, 132 kV)',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'3',
      text:'Furnace Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'4',
      text:'Generator Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'5',
      text:'Auxiliary Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
    {
      id:'6',
      text:'Lighting Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'7',
      text:'Hermetically sealed Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'8',
      text:'Isolation Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'9',
      text:'Earthing Transformer',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
    {
      id:'10',
      text:'Solar & Wind Application Transformers',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },  
  ],

}  
]

export const productImgData = [
    {
    id:'1',
    imgSrc:require('../assets/src/product/productImg1.png')
    },
    {
    id:'2',
    imgSrc:require('../assets/src/product/productImg2.png')
    },
    {
    id:'3',
    imgSrc:require('../assets/src/product/ProductImg3.png')
    },
    {
    id:'4',
    imgSrc:require('../assets/src/product/productImg4.png')
    },
    {
    id:'5',
    imgSrc:require('../assets/src/product/productImg5.png')
    },
    {
    id:'6',
    imgSrc:require('../assets/src/product/productImg6.png')
    },
]



// export const dataProducts=[
//     {
//         id:'1',
//         title:'AuxiliaryTransformers',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/auxiliaryTransformer.png')
//     },
//     {
//         id:'2',
//         title:'Lighting Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/lightingTransformer.png')
//     },
//     {
//         id:'3',
//         title:'Hermetically Sealed Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/hermeticallySealed.png')
//     },
//     {
//         id:'4',
//         title:'Isolation Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/isolation.png')
//     },
//     {
//         id:'5',
//         title:'Earthing Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/earthing.png')
//     },
//     {
//         id:'6',
//         title:'Solar & Wind Application Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/solarWind.png')
//     },
//     {
//         id:'7',
//         title:'Generator Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/generator.png')
//     },
//     {
//         id:'8',
//         title:'Furnace Transformer',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/furnace.png')
//     },
//     {
//         id:'9',
//         title:'Power Transformer (up to 16 MVA, 132 KV)',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/power.png')
//     },
//     {
//         id:'10',
//         title:'Distribution Transformer as per BIS Energy Level 1, 2, 3 – Voltage Class 11kV, 22kV, 33kV',
//         subTitle:'SPS Chennai',
//         text:'Auxiliary transformers are used for lighting purposes, heating the train wagons or for producing single-phase auxiliary',
//         imgSrc:require('../assets/src/product/distribution.png')
//     },
// ]

export const dataServices=[
    {
        id:'1',
        title:'Maintenance program',
        text:'We offer comprehensive maintenance programs that define maintenance work and the required spare parts for different stages. The programs ensure the best possible usability and performance of the equipment.',
    },
    {
        id:'2',
        title:'Guidelines for maintenance',
        text:'We also provide suggestions for preventive maintenance and spare parts programs. Our preventive approach helps eliminate many potential problems. Please feel free to contact us.',
    }
    
]