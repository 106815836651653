import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Box, Grid, Stack, Container } from '@mui/material';
import {List,ListItem,ListItemIcon} from '@mui/material';
import {dataLocation} from '../../data/Contact';
import { Link } from 'react-router-dom';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';

const LocationBox = styled(Box)(({theme}) => ({
    backgroundColor:theme.palette.comman.main,
    color:theme.palette.primary.dark,
    textTransform:'capitalize',
    boxShadow: '0px 9px 45px rgba(0, 0, 0, 0.09)',
    borderRadius:theme.spacing(2)
  }));

const Credentials = (props) => {
  const handleOpen = useOutletContext();
  return (
    <React.Fragment>
    <Box sx={{bgcolor:'#ffffff',py:{xs:8,md:10}}}>
    <Container>
    <LocationBox sx={{p:{xs:4,md:8}}}>
    {dataLocation.map((item,index)=>
    <Grid container textAlign={{xs:'center',md:'left'}} columnSpacing={10} rowSpacing={6} key={item.id}>
  <Grid item xs={12} md={6}>
    <Typography variant='h2'>
    {item.title}
    </Typography>
    <List>
      <ListItem>
        {/* <img src={item.imgSrc} alt={item.imgAlt} style={{width:'20px',height:'20px',marginTop:'5px'}}/> */}
      <Typography variant='body1' color='text.main' sx={{textAlign:{xs:'center',md:'left'}}} mb={4}>{item.text}</Typography>
      </ListItem>
      <Stack direction="row" mb={4} sx={{justifyContent:{xs:'center',md:'start'}}}>
        <IconButton size="large" sx={{color:'primary.dark'}}>
        <FacebookOutlinedIcon fontSize="inherit" />
        </IconButton>
        <IconButton size="large" sx={{color:'primary.dark'}}>
        <TwitterIcon fontSize="inherit" />
      </IconButton>
      <Link to="https://www.linkedin.com/company/sps-transformers-pvt-ltd/" target="_blank">
        <IconButton size="large" sx={{color:'primary.dark'}}>
        <LinkedInIcon fontSize="inherit" />
      </IconButton>
      </Link>
      </Stack>
  </List>
  <Button variant={item.variant} color='primary' size="large"onClick={props.handleOpen}>{item.buttonText}</Button>
  </Grid>
  <Grid item xs={12} md={6}>
    {item.location.map((item,index)=>
    <Stack key={item.id} direction={{ xs: 'column', md: 'row' }}
    spacing={{ xs: 3,md:6 }} sx={{borderBottom:'1px solid',borderColor:'primary.main',mb:4}}>
    <img src={item.iconSrc} alt={item.iconAlt} className='icon-auto' style={{width:'40px',height:'40px'}}/>
    <Box>
    <Typography variant='h5'>
    {item.title}
    </Typography>
    <Typography variant='body1' color='text.main'>
    {item.text}
    </Typography>
    <Link to={item.link} target="_blank">
    <Typography variant='h6' sx={{mb:{xs:4,md:6}}} color='primary'>
    {item.linkText}
    </Typography>
    </Link>
    </Box>
    </Stack>
    )}
  </Grid>
  </Grid> )}
  </LocationBox>
  </Container>
  </Box>
    </React.Fragment>
  )
}

export default Credentials