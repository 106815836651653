import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid, Stack } from '@mui/material';
import { ClientsIndustriesData ,ClientsGovernmentData, ClientsEducationData,
  ClientsHealthData,ClientsSolarData, } from '../../data/Clients';
import SchoolIcon from '@mui/icons-material/School';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import FactoryIcon from '@mui/icons-material/Factory';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import Hero from '../../components/Hero';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
    <Hero title="Trusted Partners"
    text="Our valued clients inspire us to craft products, fostering lasting partnerships built on trust and results."
    className="team"
    buttonText="Contact us"
    color="primary"
    variant="contained"
    link="/contact"
    />
    <Box sx={{py:{xs:3,md:6,lg:8}}}>
    <Container>
    <Box
    //   sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'grid' }}
    > <Grid container spacing={4}>
        <Grid item xs={6} md={3} style={{position:'sticky',top:'90px'}}>
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider',alignItems:'flex-start' }}
      >
        <Tab label="Government" icon={<AssuredWorkloadIcon />} iconPosition="start" {...a11yProps(0)} />
        <Tab label="Industries" icon={<FactoryIcon />} iconPosition="start" {...a11yProps(1)} />
        <Tab label="Solar" icon={<SolarPowerIcon />} iconPosition="start" {...a11yProps(2)} />
        <Tab label="Health Care" icon={<LocalHospitalIcon />} iconPosition="start" {...a11yProps(3)} />
        <Tab label="Educational Institution" icon={<SchoolIcon />} iconPosition="start" {...a11yProps(4)} />
      </Tabs>
      </Grid>
      <Grid item xs={6} md={9}>
      <TabPanel value={value} index={0}>
        <Grid container spacing={4}>
        {ClientsGovernmentData.map((clients)=>
         <Grid item xs={6} md={2} >
        <Stack alignItems='center' justifyContent='center'>
        <img src={clients.imgSrc} alt={clients.imgSrc} width='75%' style={{marginLeft:'auto',marginRight:'auto',aspectRatio:'4/3',objectFit:'contain'}}/>
        </Stack>
         </Grid>
        )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Grid container spacing={4}>
        {ClientsIndustriesData.map((clients)=>
         <Grid item xs={6} md={2}>
        <Box>
        <img src={clients.imgSrc} alt={clients.imgSrc} width='75%' style={{marginLeft:'auto',marginRight:'auto',aspectRatio:'4/3',objectFit:'contain'}}/>
        </Box>
         </Grid>
        )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Grid container spacing={4}>
        {ClientsSolarData.map((clients)=>
         <Grid item xs={6} md={2}>
        <Box>
        <img src={clients.imgSrc} alt={clients.imgSrc} width='75%' style={{marginLeft:'auto',marginRight:'auto',aspectRatio:'4/3',objectFit:'contain'}}/>
        </Box>
         </Grid>
        )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Grid container spacing={4}>
        {ClientsHealthData.map((clients)=>
         <Grid item xs={6} md={2}>
        <Box>
        <img src={clients.imgSrc} alt={clients.imgSrc} width='75%' style={{marginLeft:'auto',marginRight:'auto',aspectRatio:'4/3',objectFit:'contain'}}/>
        </Box>
         </Grid>
        )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Grid container spacing={4}>
        {ClientsEducationData.map((clients)=>
         <Grid item xs={6} md={2}>
        <Box>
        <img src={clients.imgSrc} alt={clients.imgSrc} width='75%' style={{marginLeft:'auto',marginRight:'auto',aspectRatio:'4/3',objectFit:'contain'}}/>
        </Box>
         </Grid>
        )}
        </Grid>
      </TabPanel>
      </Grid>
    </Grid>
    </Box>
    </Container>
    </Box>
    </React.Fragment>
  );
}