import React from 'react'
import Hero from '../../components/Hero';
import Hiring from './Hiring';
import Block from './Block';
import CallToAction from '../../components/CallToAction';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import RequestAQuote from '../../components/RequestAQuote';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

const Carrier = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
    <Hero title="Join our team ,we’ll figure out if you’re a good fit!"
    className="carrier"
    buttonText="Explore about us"
    color="primary"
    variant="contained"
    link="/about/overview"
    />
    <Hiring />
   <Block/>
   <Box sx={{marginTop:(theme)=>theme.spacing(-24)}}>
    <CallToAction title="The Building Blocks Of our Culture " text="At SPS, we adopt a holistic approach towards the workspace."
    buttonTextSecondary="Get a Quote" onSecondaryClick={handleOpen} />
    </Box>
    <RequestAQuote open={open}  handleClose={handleClose} />
    </React.Fragment>
  )
}

export default Carrier