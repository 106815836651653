import React, { useEffect, useState } from 'react';
import OurProduct from './OurProduct';
import OurInfra from './OurInfra';
import Hero from '../../../components/Hero';
import { useLocation } from "react-router-dom";

const Gallery = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    const [img,setImg]=useState([]);
    useEffect(()=>{
        fetchImg()
        },[])
    const fetchImg = async () =>{
    const res= await fetch('https://swapi.dev/api/planets/');
    const data = await res.json();
    const transform = data.results.map((item)=>{
    return{
    id:item.name,
    }
    })
    setImg(transform);
    }

   

  return (
   <React.Fragment>
    <Hero title="Our infrastructure & Our Products"
    className="carrier"
    buttonText="Explore about us"
    color="primary"
    link="/about/overview"
    variant="contained"
    />
    <OurInfra img={img} />
    <p>{img.id}</p>
    <OurProduct />
   </React.Fragment>
  )
}

export default Gallery