import React, { useEffect, useState } from 'react'
import DataUpdate from './DataUpdate';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import { Container } from '@mui/material';

const NewsUpdate = () => {

  return (
   <React.Fragment>
    <DataUpdate />
   </React.Fragment>
  )
}

export default NewsUpdate