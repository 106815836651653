export const dataContactUs=[
    {
      id:'1',
      title:'contact us',
      sub:[
      {
      id:'corporate',
      subTitle:'Corporate Office',
      number1:'044-43324022',
      number2:'044-43325022',
      },
      {
      id:'Factory',
      subTitle:'Factory',
      number1:'+91 9384663033',
      number2:'+91 9384663042',
      },
      {
      id:'Service Support',
      subTitle:'Service Support',
      number1:'+91 9884112176',
      text1:'Locate Factory',
      link1:'/contact',
      text2:'Locate Corporate Office',
      link2:'/contact',
      },
      ]

    }
  ]

export const dataAbout=[
    {
      id:'1',
      title:'About',
      list:[
    {id:'1',
    listItem:'overview',
    link:'/about/overview'},
    {id:'2',
    listItem:'team',
    link:'/about/team'},
    {id:'3',
    listItem:'infra',
    link:'/about/infra'},
    {id:'4',
    listItem:'careers',
    link:'/about/carrier'},
    {id:'5',
    listItem:'vision',
    link:'/about/overview'},
    {id:'6',
    listItem:'mission',
    link:'/about/overview'},
    {id:'7',
    listItem:'core values',
    link:'/about/overview'},
    {id:'8',
    listItem:'clients',
    link:'/about/clients'}
  ]
    }
  ]

  export const dataPolicyProduct=[
    {
      id:'1',
      title:'Policy & Credentials',
      sub:[
      {
      id:'Products',
      text1:'Products',
      link1:'/policycredentials',
      text2:'Integrated Management Policy',
      link2:'/policycredentials',
      text3:'Credentials',
      link3:'/policycredentials',
      },
      {
      id:'service',
      subTitle:'Products & Services',
      text1:'Products',
      link1:'/productservices',
      text2:'Services',
      link2:'/productservices',
      text3:'Engineering',
      link3:'/engineering',
      },
      ]

    }
  ]
