import React from 'react';
import { Container, Typography, Box, Grid} from '@mui/material';
import {Card,CardActions,CardContent,CardMedia} from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import aboutSps from '../../assets/src/post/aboutSps.jpg';
import { Link } from 'react-router-dom';

const AboutBox = styled(Box)(({theme}) => ({
   
  }));

const AboutSps = () => {
  return (
    <React.Fragment>
        <AboutBox sx={{py:{xs:3,md:6,lg:8}}}>
        <Container>
        <Grid container alignItems='center' rowSpacing={4}>
        <Grid item xs={12} md={8}>
        <Box style={{overflow:'hidden',borderRadius:'24px'}}>
      <img src={aboutSps} title="sps_transformer" alt="sps_transformer" className='zoomImgHover' style={{width:'100%',display:'block'}} />
      </Box>
      </Grid>
      <Grid item xs={12} md={4}>
    <Card sx={{padding:'3rem 2rem',borderRadius:3,ml:{xs:'auto',md:-15},mt:{xs:-23,md:'auto'},maxWidth:{xs:'90%',md:'fit-content'},mx:'auto'}} style={{position:'relative'}}>
    <CardContent sx={{p:0}}>
      <Typography variant="h2" mb={3}>
      About SPS
      </Typography>
      <Typography variant="body1" color="text.main" mb={3}>
      SPS Transformers Private Limited has been certified by TUV SUD South Asia Private Limited as an ISO 9001:2015 Certified Company which has emerged from a business house which, for 4 decades, stamped it’s authority in the field of transformer making.
      </Typography>
    </CardContent>
    <CardActions sx={{p:0}}>
      <Link to="/about/overview">
      <Button size="large" color="primary" variant='outlined'>Learn more</Button>
      </Link>
    </CardActions>
  </Card>
  </Grid>
  </Grid>
  </Container>
  </AboutBox>
    </React.Fragment>
  )
}

export default AboutSps