
export const dataFactory=[
  {
  id:'factory',
  title:'Our Factory policies',
  buttonText:'Request a quote',
  variant:'contained',
  factory:[
    {
      id:'1',
      text:'We are committed to provide satisfaction to our customers by manufacturing Power and Distribution Transformers and commissioning of turnkey projects with the aim to achieve expectations of them by continual improvement of systems and process.',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
      {
      id:'2',
      text:'Timely delivery of quality products and services at a very competitive price including compliance to product.',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
      {
      id:'3',
      text:'EHS and other legal statutory, regulatory requirements to prevent environmental pollution and ill health of employees/society.',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
      {
      id:'4',
      text:'In order to meet every need and kind of service, SPS has a fully qualified service/ maintenance team. We always focus on providing value to the customer and also on the customer relationship.',
      imgSrc:require('../assets/src/icon/flaticon/thunder.png'),
      imgAlt:'thunder-img'
    },
  ],
  card:[
{
    id:'1',
    title:'Competitive Price',
},
  {
    id:'2',
    title:'Time management',
},
{
    id:'3',
    title:'High Quality Production Line',
},
{
    id:'4',
    title:'Fully Qualified service/maintenance',
},
]
}  
]


export const dataQuality=[
  {
  id:'overview',
  imgSrc:require('../assets/src/post/policiesIllustrator.png'),
  imgAlt:'policiesIllustrator-img',
  title:'Our quality policy',
  text:'We at SPS Transformers Private Limited are committed to Designing, Manufacturing & Supplying of Oil Cooled Power and Distribution Transformer with the best of Quality, On-Time delivery to our Customer Satisfaction. To achieve this, we constantly improve our process related QMS by understanding the needs and requirements of every customer and other interested parties by evaluating the effective risks and management opportunities.',
  buttonText:'lets connect',
  variant:'contained',
  link:'/contact',
  quality:[
  {
    id:'1',
    text:'Stakeholder’s relationships to be improved internally and externally by effective communication.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
  {
    id:'2',
    text:'Product output to exhibit high quality and consistency as measured against customer’s requirement.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
  {
    id:'3',
    text:'Suppliers and Contractors relationship to be mutually developed to focus to comply with our requirement in a consistent manner.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
    {
    id:'4',
    text:'Transform everyone’s job in order to transform the organization from good to greater performance.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
    {
    id:'5',
    text:'Enable, Engage, Encourage and Enrich our Employees in view of their continual improvement.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
    {
    id:'6',
    text:'Adapting to the update in every field of the industry to enhance customized design.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
    {
    id:'7',
    text:'Maintain and continually upgrade EHS Management System.',
    imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
    imgAlt:'bold-img'
  },
  ]
  }  
]


export const dataCredentials=[
  {
  id:'Credentials',
  title:'Credentials',
  buttonText:'BIS Certification',
  link:'/contact',
  variant:'contained',
  credential:[
    {
      id:'1',
      text:'Successfully Type Tested 8MVA, 33/11kV class Transformers.',
      imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
      imgAlt:'thunder-img'
      },
      {
      id:'2',
      text:'Successfully Conducted Type Test on 100kVA 11kV, 100kVA 22kV, 200kVA 22kV, 250kVA 11kV, 500kVA 11kV',
      imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
      imgAlt:'thunder-img'
      },
      {
      id:'3',
      text:'500kVA 22kV, 630kVA 11kV, 630kVA 22kV, 800kVA 11kV, 800kVA 22kV, 1000kVA 11kV, 1250kVA 33kV, 1600kVA 11kV',
      imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
      imgAlt:'thunder-img'
      },
      {
      id:'4',
      text:'2000kVA 11kV, 2000kVA 22kV, 2500kVA 11kV and 2500kVA 33kV Distribution Transformers.',
      imgSrc:require('../assets/src/icon/flaticon/bolt.png'),
      imgAlt:'thunder-img'
      },
  ],
  certified:[
  {
  id:'1',
  iconSrc:require('../assets/src/icon/certified.png'),
  iconAlt:'certified-icon',
  title:'BIS certified',
  text:'2500 kva 33 kv, 3 phase, CRGO core,copper wound non sealed EEL 1,2&3.',
  },
  {
  id:'2',
  iconSrc:require('../assets/src/icon/certified.png'),
  iconAlt:'certified-icon',
  title:'BIS certified',
  text:'2500 kva 11 kv 3 phase, CRGO core , copper wound non sealed EEL 1,2&3.'
},
  {
  id:'3',
  iconSrc:require('../assets/src/icon/certified.png'),
  iconAlt:'certified-icon',
  title:'BIS certified',
  text:'2500 kva 11/22/33 kv, 3 phase, CRGO core, copper wound non sealed Distribution Transformer As Per IS1180 Standard.'
},
]
}  
]





export const dataDiscuss=[
  {
  id:'01',
  title:'35 T Cranes',
  text:'Capable to lift heavy weight',
  img: require('../assets/src/infra/cranes.png'),
  imgAlt:'Cranes-img'
  },
  {
  id:'02',
  title:'Epoxy shop floor',
  text:' For Dust free ans anti-slip',
  img: require('../assets/src/infra/epoxyShop.png'),
  imgAlt:'Floor-img'
  },
  {
  id:'03',
  title:'Painting booth',
  text:' For Dust free Painting',
  img: require('../assets/src/infra/paintingBooth.png'),
  imgAlt:'Booth-img'
  },
]