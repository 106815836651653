
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import Slider from "react-slick";
import { imgGallery } from '../../../data/Gallery';
import electricityLoading from '../../../assets/src/icon/lottie/electricityLoading.gif';

const GalleryBox = styled(Box)(({theme}) => ({
    
}))

const OurProduct = () => {

    const ourInfra = {
    dots: false,
    autoplay:true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows : false,
        }
      },
    ]
  };

  return (
   <React.Fragment>
    <GalleryBox className='gallery' sx={{py:{xs:3,md:6}}}>
    <Container>
    <Box>
    <Stack direction="row" spacing={2} useFlexGap>
    <Typography variant='h2'>Our Products</Typography>
    <img src={electricityLoading} alt="electricityLoading-gif" width="50px" height="50px" />
    </Stack>
    <Typography variant='body1' color="text.main" mb={4}>
    Our production and quality team constantly work towards continuous improvement of our manufacturing processes to make the best & most efficient product possible for you
    </Typography>
    </Box>
    <Slider {...ourInfra} style={{paddingBottom:'5rem'}}>
    {imgGallery.map((img,index)=>{
    return(
    <>
    <Box className='centerMode' sx={{margin:'16px'}} key={index}>
    <img src={img.imgSrc} style={{width:'100%',borderRadius:'16px'}}/>
    </Box>
    </>
    )
    }
    )}
    </Slider>
    </Container>
    </GalleryBox>
   </React.Fragment>
  )
}

export default OurProduct